import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import ImportDialog from '../../components/ImportDialog/ImportDialog';
import TransfersSalaries from '../../components/Transfers/TransfersSalaries/TransfersSalaries';
import TransfersTalenom from '../../components/Transfers/TransfersTalenom/TransfersTalenom';
import CustomDialog from '../../components/UI/CustomDialog/CustomDialog';
import Scrollable from '../../hoc/Scrollable/Scrollable';
import {
  IAppState,
  IProcountorLedgerReceiptsTransfer,
  ISalaryImport,
  ISalaryImportTransfer,
  ITalenomCSVTransfer,
} from '../../interfaces';
import * as actions from '../../store/actions';

interface IStateProps {
  ledgerReceiptsTransfers: Array<IProcountorLedgerReceiptsTransfer> | null;
  ledgerReceiptsLoading: boolean;
  salaryTransfers: Array<ISalaryImportTransfer> | null;
  salaryLoading: boolean;
  importSpinner: boolean;
  importError: string;
  salaries: Array<ISalaryImport>;
  createSpinner: boolean;
  createError: string;
  talenomTransfers: ITalenomCSVTransfer[] | null;
  talenomLoading: boolean;
}

interface IDispatchProps {
  onFindLedgerReceiptsTransfers: () => void;
  onFindSalaryImportTransfers: () => void;
  onFindTalenomTransfers: () => void;
  onDeleteSalaryImportTransfer: (id: string) => Promise<boolean>;
  onUploadProductionSalaries: (formData: FormData) => void;
  onClearProductionSalariesState: () => void;
  onImportProductionSalaries: (
    salaries: Array<ISalaryImport>
  ) => Promise<boolean>;
  onCreateLedgerReceiptsTransfer: (data: any) => Promise<boolean>;
  onDeleteLedgerReceiptsTransfer: (id: string) => Promise<boolean>;
  onDeleteTalenomTransfer: (id: string) => Promise<boolean>;
}

interface IProps extends IStateProps, IDispatchProps {}

const initDialog = {
  open: false,
  title: "",
  text: "",
  onOk: () => {},
};

const Transfers: React.FC<IProps> = ({
  onFindSalaryImportTransfers,
  onDeleteSalaryImportTransfer,
  onFindTalenomTransfers,
  salaryLoading,
  salaryTransfers,
  onUploadProductionSalaries,
  importSpinner,
  importError,
  salaries,
  onClearProductionSalariesState,
  onImportProductionSalaries,
  talenomLoading,
  talenomTransfers,
  onDeleteTalenomTransfer,
}) => {
  const [dialog, setDialog] = useState(initDialog);
  const [showImportDialog, setShowImportDialog] = useState(false);
  // const [showProcountorTransfer, setShowProcountorTransfer] = useState(false);

  useEffect(() => {
    onFindSalaryImportTransfers();
    onFindTalenomTransfers();
  }, [onFindSalaryImportTransfers, onFindTalenomTransfers]);

  const onConfirmSalaryImportTransferDelete = (id: string) => {
    setDialog({
      open: true,
      title: "Delete salary import?",
      text: "Are you sure you want to delete salary transfer?",
      onOk: async () => {
        setDialog(initDialog);
        const success = await onDeleteSalaryImportTransfer(id);
        if (success) {
          onFindSalaryImportTransfers();
        }
      },
    });
  };

  const onConfirmTalenomTransferDelete = (id: string) => {
    setDialog({
      open: true,
      title: "Delete talenom transfer?",
      text: "Are you sure you want to delete talenom transfer?",
      onOk: async () => {
        setDialog(initDialog);
        const success = await onDeleteTalenomTransfer(id);
        if (success) {
          onFindTalenomTransfers();
        }
      },
    });
  };

  /*
  const onConfirmLedgerReceiptsTransferDelete = (id: string) => {
    setDialog({
      open: true,
      title: "Delete procountor transfer?",
      text: "Are you sure you want to delete procountor transfer?",
      onOk: async () => {
        setDialog(initDialog);
        const success = await onDeleteLedgerReceiptsTransfer(id);
        if (success) {
          onFindLedgerReceiptsTransfers();
        }
      },
    });
  };
  */

  const onImport = async (salaries: Array<ISalaryImport>) => {
    const success = await onImportProductionSalaries(salaries);
    if (success) {
      onCloseImportDialog();
      onFindSalaryImportTransfers();
    }
  };

  const onCloseImportDialog = () => {
    setShowImportDialog(false);
    onClearProductionSalariesState();
  };

  /*
  const onSubmitProcountorTransfer = async (data: any) => {
    const success = await onCreateLedgerReceiptsTransfer(data);
    if (success) {
      setShowProcountorTransfer(false);
      onFindLedgerReceiptsTransfers();
    }
    return success;
  };
  */
  return (
    <React.Fragment>
      {/*
        <Sidebar
        title="Create procountor transfer"
        show={showProcountorTransfer}
        onClose={() => setShowProcountorTransfer(false)}
      >
        <TransferProcountorForm 
          onSubmit={onSubmitProcountorTransfer}
          onCloseSidebar={() => setShowProcountorTransfer(false)}
          loading={createSpinner}
          error={createError}
        />
      </Sidebar>
    
  */}

      <ImportDialog
        onClose={onCloseImportDialog}
        open={showImportDialog}
        onUpload={onUploadProductionSalaries}
        loading={importSpinner}
        error={importError}
        salaries={salaries}
        onImport={onImport}
      />
      <CustomDialog
        open={dialog.open}
        title={dialog.title}
        onCancel={() => setDialog(initDialog)}
        onOk={dialog.onOk}
      >
        {dialog.text}
      </CustomDialog>
      <Scrollable>
        {/*
        
        <TransfersProcountor
          ledgerReceiptsTransfers={ledgerReceiptsTransfers}
          loading={ledgerReceiptsLoading}
          onCreate={() => setShowProcountorTransfer(true)}
          onDelete={onConfirmLedgerReceiptsTransferDelete}
        />
*/}
        <TransfersTalenom
          talenomTransfers={talenomTransfers}
          loading={talenomLoading}
          onDelete={onConfirmTalenomTransferDelete}
        />


        <TransfersSalaries
          salaryImportTransfers={salaryTransfers}
          loading={salaryLoading}
          onDelete={onConfirmSalaryImportTransferDelete}
          onImport={() => setShowImportDialog(true)}
        />
      </Scrollable>
    </React.Fragment>
  );
};

const mapStateToProps = (state: IAppState): IStateProps => {
  return {
    ledgerReceiptsTransfers: state.procountor.ledgerReceiptsTransfers,
    ledgerReceiptsLoading: state.procountor.loading,
    salaryTransfers: state.productions.salaryTransfers,
    salaryLoading: state.productions.loading,
    importSpinner: state.productions.importSpinner,
    importError: state.productions.importError,
    salaries: state.productions.salaries,
    createSpinner: state.procountor.createSpinner,
    createError: state.procountor.createError,
    talenomLoading: state.talenom.loading,
    talenomTransfers: state.talenom.talenomTransfers,
  };
};

const mapDispatchToProps = (dispatch: any): IDispatchProps => {
  return {
    onFindLedgerReceiptsTransfers: () =>
      dispatch(actions.findLedgerReceiptsTransfers()),
    onFindSalaryImportTransfers: () =>
      dispatch(actions.findProductionSalaryImportTransfers()),
    onDeleteSalaryImportTransfer: (id) =>
      dispatch(actions.deleteProductionSalaryImportTransfers(id)),
    onUploadProductionSalaries: (formData) =>
      dispatch(actions.uploadProductionSalaries(formData)),
    onClearProductionSalariesState: () =>
      dispatch(actions.clearProductionSalariesState()),
    onImportProductionSalaries: (salaries) =>
      dispatch(actions.importProductionSalaries(salaries)),
    onCreateLedgerReceiptsTransfer: (data) =>
      dispatch(actions.createLedgerReceiptsTransfer(data)),
    onDeleteLedgerReceiptsTransfer: (id) =>
      dispatch(actions.deleteLedgerReceiptsTransfer(id)),
    onDeleteTalenomTransfer: (id) =>  dispatch(actions.deleteTalenomTransfer(id)),
    onFindTalenomTransfers: () => dispatch(actions.findTalenomTransfers()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Transfers);
