import React from 'react';
import { Table } from 'reactstrap';

import { createCategoriesArray } from '../../../data/dimension-category';
import { IProduction, IProductionDimension, IUser } from '../../../interfaces';
import ProductionBudgetCategory from './ProductionBudgetCategory/ProductionBudgetCategory';

const classes = require("./ProductionBudget.module.scss");

interface IProps {
  production: IProduction;
  canEdit: boolean;
  currentUser: IUser;
  onDeleteDimension: (dimension: IProductionDimension) => void;
  onEditDimension: (dimension: IProductionDimension) => void;
}

const ProductionBudget: React.FC<IProps> = ({
  production,
  canEdit,
  onDeleteDimension,
  onEditDimension,
  currentUser
}) => {
  if (!production) return null;
  const { totals } = production;

  const categories = createCategoriesArray(production);
  if (categories == null) return null;

  return (
    <Table responsive className={classes.Table} striped bordered>
      <thead>
        <tr>
          <th />
          <th>Unit</th>
          <th>Amount</th>
          <th>á-price</th>
          <th>Budget</th>
          <th>Notes</th>
        </tr>
      </thead>
      {categories.map((item, index) => (
        <ProductionBudgetCategory
          key={index}
          dimensions={item.dimensions}
          title={item.title}
          subTitle={item.subTitle}
          canEdit={canEdit}
          emptyRow={item.emptyRow}
          budgetTotal={item.totals && item.totals.budget}
          budgetTotalTitle={item.totalsTitle}
          subBudgetTotal={item.subTotals && item.subTotals.budget}
          subBudgetTotalTitle={item.subTotalsTitle}
          onDeleteDimension={onDeleteDimension}
          onEditDimension={onEditDimension}
          currentUser={currentUser}
        />
      ))}
      <tfoot>
        <tr>
          <th colSpan={4}>Total</th>
          <th>{totals.budget} €</th>
          <th></th>
        </tr>
      </tfoot>
    </Table>
  );
};

export default ProductionBudget;
