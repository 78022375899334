import React from 'react';
import { connect } from 'react-redux';

import Footer from '../../components/Footer/Footer';
import { IAppState, IUser } from '../../interfaces';

const classes = require("./Scrollable.module.scss");

interface IStateProps {
  isAuthenticated: boolean;
  currentUser: IUser;
}

interface IProps extends IStateProps {
  children: any;
}

const Scrollable: React.FC<IProps> = props => {
  return (
    <div className={classes.Container}>
      <div className={classes.Body}>{props.children}</div>
      <Footer isAuthenticated={props.isAuthenticated} />
    </div>
  );
};

const mapStateToProps = (state: IAppState): IStateProps => {
  return {
    isAuthenticated: state.auth.currentUser !== null,
    currentUser: state.auth.currentUser
  };
};

export default connect(mapStateToProps)(Scrollable);
