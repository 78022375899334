import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { Alert, Button, Form } from "reactstrap";

import { roles } from '../../../data/user-role';

import ContentBox from "../../../hoc/ContentBox/ContentBox";
import { IAppState, IUser } from "../../../interfaces";
import {
  controlsToFormGroups,
  getFormData,
  initForm,
  validateInput
} from "../../../shared/utility";
import * as actions from "../../../store/actions";
import Scrollable from "../../../hoc/Scrollable/Scrollable";

interface IStateProps {
  currentUser: IUser | null;
  user: IUser | null;
  error: string | null;
  loading: boolean;
  success: boolean;
}

interface IDispatchProps {
  onGetUser: (id: string) => {};
  onUpdateUser: (id: string, formData: {}) => {};
  onSaveUser: (formData: {}) => {};
}

interface IMatchProps {
  id: string;
}

interface IProps
  extends IStateProps,
    IDispatchProps,
    RouteComponentProps<IMatchProps> {}

const User: React.FC<IProps> = props => {
  const { onGetUser, user, error, match } = props;

  const getControls = (): any => {
    return {
      firstName: {
        elementType: "input",
        elementConfig: {
          label: "First name",
          type: "text"
        },
        value: "",
        validation: {
          required: true
        },
        valid: false
      },
      lastName: {
        elementType: "input",
        elementConfig: {
          label: "Last name",
          type: "text"
        },
        value: "",
        validation: {
          required: true
        },
        valid: false
      },
      email: {
        elementType: "input",
        elementConfig: {
          label: "Email",
          type: "email"
        },
        value: "",
        validation: {
          required: true,
          email: true
        },
        valid: false
      },
      phoneNumber: {
        elementType: "input",
        elementConfig: {
          label: "Phone number",
          type: "text"
        },
        value: "",
        validation: {},
        valid: true
      },
      allowLogin: {
        elementType: "checkbox",
        elementConfig: {
          label: null,
          type: "checkbox",
          options: [{ id: true, label: "Allow login" }]
        },
        validation: {},
        value: false,
        valid: true
      },
      role: {
        elementType: "radio",
        elementConfig: {
          label: "Role",
          type: "radio",
          options: roles,
          validation: {
            required: true
          },
          value: "",
          valid: false
        }
      }
    };
  };

  const [state, setState] = useState({
    controls: getControls(),
    formIsValid: false
  });

  useEffect(() => {
    const { id } = match.params;
    if (id !== "add") {
      onGetUser(id);
    }
  }, [onGetUser, match.params]);

  useEffect(() => {
    if (user) {
      const data = initForm(getControls(), user);
      setState({
        controls: data.controls,
        formIsValid: data.formIsValid
      });
    }
  }, [user]);

  const submitHandler = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const id = user ? user.id : undefined;
    const formData = getFormData(state.controls);
    if (id) {
      await props.onUpdateUser(id, formData);
    } else {
      await props.onSaveUser(formData);
    }
    props.history.push(`/users`);
  };

  const inputChangedHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
    controlName: string
  ) => {
    const validation = validateInput(
      state.controls,
      controlName,
      event.target.value
    );

    setState({
      controls: validation.controls,
      formIsValid: validation.formIsValid
    });
  };

  return (
    <Scrollable>
      <ContentBox loading={props.loading}>
        {error && <Alert color="danger">{error}</Alert>}
        <Form onSubmit={submitHandler}>
          {controlsToFormGroups(state.controls, inputChangedHandler)}
          <Button type="submit" color="primary" disabled={!state.formIsValid}>
            Submit
          </Button>
        </Form>
      </ContentBox>
    </Scrollable>
  );
};

const mapStateToProps = (state: IAppState): IStateProps => {
  return {
    currentUser: state.auth.currentUser,
    user: state.users.user,
    loading: state.users.loading,
    success: state.users.success,
    error: state.users.error
  };
};

const mapDispatchToProps = (dispatch: any): IDispatchProps => {
  return {
    onGetUser: id => dispatch(actions.getUser(id)),
    onSaveUser: user => dispatch(actions.saveUser(user)),
    onUpdateUser: (id, user) => dispatch(actions.updateUser(id, user))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(User);
