import { EProcountorLedgerReceiptsTranferStatus } from '../enums/procountor-ledger-receipts-transfer';
import { EProductionGroup } from '../enums/production';
import { EDimensionCategory, EDimensionUnit } from '../enums/production-dimension';
import { EProductionDimensionExpenseSource } from '../enums/production-dimension-expense';
import { EUserRole } from './../enums/user-role';


export interface IUser {
  id:string;
  email:string;
  name: string;
  role: EUserRole;
  logoutUrl: string;
  phoneNumber: string;
}

export interface IProduction {
  id:string;
  updated: string;
  nameFi: string;
  nameEn: string;
  group: EProductionGroup;
  salesPrice: number;
  salesPriceDisplay: string;
  dimensions: Array<IProductionDimension>;
  salariesTotal: IProductionTotals;
  salariesWithExpensesTotal: IProductionTotals;
  subcontractingTotal: IProductionTotals;
  personnelExpensesTotal: IProductionTotals;
  advanceResearchTotal: IProductionTotals;
  productionTotal: IProductionTotals;
  otherExpensesTotal: IProductionTotals;
  postProductionsTotal: IProductionTotals;
  totals: IProductionTotals;
  totalBudgetWithExpenses: number;
  totalEstimateWithExpenses: number;
  marginEur: number;
  marginPercent: number;
  accCosts: number;
  accMarginEur: number;
  accMarginPercent: number;
  producers: Array<IUser>;
  canEdit: boolean;
}

export interface IProductionDimension {
  id: string;
  name: string,
  accountNumber: number;
  unit: EDimensionUnit;
  amount: number;
  price: number;
  priceDisplay: string;
  estimate: number;
  notes: string;
  estimateDisplay: number;
  category: EDimensionCategory;
  budget: number;
  expenseValueTotal: number;
  expenseValueTotalDisplay: string;
  expenseExpensesTotal: number;
  expenseExpensesTotalDisplay: string;
  totalPrice: number;
  statusPrice: number;
  canViewExpenses: boolean;
  // expenses: Array<IProductionDimensionExpense>;
}

export interface IProductionTotals {
  budget: string; 
  expensesValue: string; 
  estimate: string;
  total: string;
  statusDisplay: string;
  status: number;
}

export interface IProductionDimensionExpense {
  id: string;
  value: number;
  source: EProductionDimensionExpenseSource;
}

export interface IProductionDimensionExpenseProcountor extends IProductionDimensionExpense {
  receiptId: number;
  transactionId: number;
  status: string;
  receiptDate: string;
  receiptName: string;
  description: string;
}

export interface IProductionDimensionExpenseCSVTalenom extends IProductionDimensionExpense {
  receiptId: number;
  receiptDate: string;
  invoiceNumber: string;
  notes: string;
}

export interface IProductionDimensionExpenseCSVSalaries extends IProductionDimensionExpense {
  expenses: number;
  startDate: string;
  endDate: string;
  paymentDate: string;
  personName: string;
  personNumber: number;
}

export interface ISalaryImport {
  id: string;
  personNumber: number;
  personName: string;
  dateRange: string;
  paymentDate: string;
  items: Array<ISalaryImportItem>;
}

export interface ISalaryImportItem {
  id: string;
  expenses: number;
  expensesDisplay: string;
  price: number;
  priceDisplay: string;
	costPoolNumber: number;
	projectNumber: string;
}

export interface IProcountorLedgerReceiptsTransfer {
  id: string;
  created: string;
  startDate: string;
  endDate: string;
  versionStartDate: string;
  versionEndDate: string;
  status: EProcountorLedgerReceiptsTranferStatus;
}

export enum ESalaryImportTransferStatus {
  DONE = 'DONE',
  DELETING = 'DELETING'
}

export interface ISalaryImportTransfer {
  id: string;
  status: ESalaryImportTransferStatus;
  created: string;
}

export enum ETalenomCSVTransferStatus {
  DONE = 'DONE',
  DELETING = 'DELETING'
}

export interface ITalenomCSVTransfer {
  id: string;
  status: ETalenomCSVTransferStatus;
  created: string;
}

export interface IProcountorLedgerReceipt {
  id: string;
  invoiceNumber: number;
  name: string;
  receiptDate: string;
  receiptId: number;
  status: string;
  type: string;
}