import React from "react";
import { FormGroup } from "reactstrap";
import Input from "../components/UI/Input/Input";
import { EUserRole } from "../enums/user-role";
import { IUser } from "../interfaces";


export function updateObject<T>(oldObject: T, updatedProperties: T): T {
  return Object.assign({}, oldObject, updatedProperties);
}

export const checkValidity = (
  value: any,
  rules: any,
  controls: any
): boolean => {
  let isValid = true;

  if (!rules) {
    return true;
  }

  if (rules.required) {
    isValid = String(value).trim() !== "" && isValid;
  }
  if (rules.minLength) {
    isValid = value.length >= rules.minLength && isValid;
  }
  if (rules.maxLength) {
    isValid = value.length <= rules.maxLength && isValid;
  }

  if (rules.email) {
    isValid = isValidEmail(value) && isValid;
  }

  if (rules.match) {
    isValid = controls[rules.match].value === value && isValid;
    controls[rules.match].valid = isValid;
  }

  return isValid;
};

export const isValidEmail = (email: string): boolean => {
  return /\S+@\S+\.\S+/.test(email);
};

export const validateInput = (
  controls: any,
  controlName: string,
  value: any
): any => {
  const elementType = controls[controlName].elementType;
  if (elementType === "checkbox") {
    let values = controls[controlName].value;
    if (typeof values === "object") {
      const index = values.indexOf(value);
      if (index !== -1) {
        values.splice(index, 1);
      } else {
        values.push(value);
      }
      value = values;
    } else {
      value = value === "true" || value === "false" ? Boolean(value) : value;
      value = values === value ? false : value;
    }
  } else if (elementType === "datepicker") {
    value = new Date(value);
  }

  const updatedControls = updateObject(controls, {
    [controlName]: updateObject(controls[controlName], {
      value,
      valid: checkValidity(value, controls[controlName].validation, controls),
      touched: true
    })
  });

  return {
    controls: updatedControls,
    formIsValid: validateControls(updatedControls)
  };
};

export const validateControls = (controls: any): boolean => {
  let formIsValid = true;
  for (let controlIdentifier in controls) {
    formIsValid = controls[controlIdentifier].valid && formIsValid;
  }
  return formIsValid;
};

export const initForm = (controls: any, data: any): any => {
  let validation = null;

  for (let key in controls) {
    const control = controls[key];

    if (control) {
      const { valueShowProperty, roles } = control;

      if (roles) {
        const { editProperty, showProperty } = roles;
        if (editProperty) {
          roles.edit = data[editProperty] && roles.edit;
        }
        if (showProperty) {
          roles.show = data[showProperty] && roles.show;
        }
      }
      if (valueShowProperty) {
        control.value = data[valueShowProperty] || '';
      }
    }
  }

  for (let key in data) {
    const control = controls[key];
    if (control) {
      validation = validateInput(controls, key, data[key]);
      controls = validation.controls;
    }
  }

  return {
    controls: validation ? validation.controls : controls,
    formIsValid: validation ? validation.formIsValid : false
  };
};

export const getFormData = (controls: any): any => {
  const formData: any = {};

  for (let formElementIdentifier in controls) {
    let value = controls[formElementIdentifier].value;
    if (value instanceof Date) {
      value = `${value.getDate()}.${value.getMonth() +
        1}.${value.getFullYear()}`;
    }

    formData[formElementIdentifier] = value;
  }
  return formData;
};

export const controlsToFormGroups = (
  controls: any,
  inputChangedHandler: any
): any => {
  const formElementsArray = [];
  for (let key in controls) {
    formElementsArray.push({
      id: key,
      config: controls[key]
    });
  }

  const formGroups = formElementsArray.map(formElement => {
    let elem: any = null;

    if (formElement.config.isLabel) {
      elem = <h3 key={formElement.config.text}>{formElement.config.text}</h3>;
    } else {
      const roles = formElement.config.roles;
      let edit = false;
      let show = false;
      if (roles) {
        edit = roles.edit || false;
        show = roles.show || false;
      } else {
        edit = true;
      }

      if (edit) {
        elem = (
          <Input
            key={formElement.id}
            formElement={formElement}
            changed={inputChangedHandler}
          />
        );
      } else if (show) {
        let value = formElement.config.value;
        let showMapper = formElement.config.showMapper;
        if (showMapper) {
          value = showMapper(value);
        }
        elem = (
          <FormGroup key={formElement.id}>
            <label>{formElement.config.elementConfig.label}</label>
            <div>{value}</div>
          </FormGroup>
        );
      }
    }
    return elem;
  });

  return formGroups;
};

export const emptyIfNull = (value: any) => {
  return value ? value : "";
};

export const zeroIfNull = (value: any) => {
  return value ? value : "0.00";
};

export const hasRoles = (user: IUser, ...roles: Array<EUserRole>) => {
  if (!user) return false;
  if (roles.indexOf(user.role) === -1) return false;
  return true;
};
