import React from "react";
import Select from "react-select";
import { ActionMeta } from "react-select/lib/types";

interface IOption {
  id: string;
  label?: string;
}

interface IProps {
  className?: string;
  onChange: (value: string) => void;
  value: any;
  options: Array<IOption>;
  placeholder?: string;
  isMulti?: boolean;
  valueMapper?: (ids:Array<string>) => any;
}

const CustomSelect: React.FC<IProps> = props => {
  const onChange = (value: any, action: ActionMeta) => {
    if (props.isMulti) {
      let ids = null;
      if (value) {
        ids = value.map((item: any) => item.id);

        if(props.valueMapper) {
          ids = props.valueMapper(ids);
        }
      }
      props.onChange(ids);
    } else {
      props.onChange(value.id);
    }
  };

  let value:any = null;
  if(props.isMulti) {
    value = props.value && props.value.map((value:any) => props.options.find((option: IOption) => option.id === value.id));
  } else {
    value = props.options.find((item: IOption) => item.id === props.value);
  }

  if(!value) {
    value = null;
  }

  return (
    <Select
      isMulti={props.isMulti}
      classNamePrefix="custom-select"
      value={value}
      getOptionValue={option => option.id}
      getOptionLabel={option => option.label}
      onChange={onChange}
      options={props.options}
      placeholder={props.placeholder}
    />
  );
};

export default CustomSelect;
