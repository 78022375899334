import React from "react";
import Dropzone from "react-dropzone";

const classes = require("./ImportDropzone.module.scss");

interface IProps {
  onDrop: (files: Array<File>) => void;
}

const ImportDropzone: React.FC<IProps> = ({ onDrop }) => {
  return (
    <Dropzone onDrop={onDrop} multiple={false}>
      {({ getRootProps, getInputProps, isDragActive }) => {
        return (
          <div className={classes.Dropzone} {...getRootProps()}>
            <input {...getInputProps()} />
            {isDragActive ? (
              <p>Drop csv here</p>
            ) : (
              <p>Try dropping csv here, or click to select csv to upload.</p>
            )}
          </div>
        );
      }}
    </Dropzone>
  );
};

export default ImportDropzone;
