import React, { useState, useContext, useMemo } from "react";
import AutoUpdate from "../AutoUpdate/AutoUpdate";
import SingleFieldAutoUpdateContext from "./SingleFieldAutoUpdateContext";

interface IProps {
  id: string;
  initialControls: any;
}

const SingleFieldAutoUpdate: React.FC<IProps> = ({ id, initialControls }) => {
  const [controls, setControls] = useState(initialControls);
  const onUpdate = useContext(SingleFieldAutoUpdateContext).onUpdate;
  
  return useMemo(() => {
    return (
      <AutoUpdate
        controls={controls}
        onUpdate={(controlName, value) => onUpdate(id, controlName, value)}
        setControls={setControls}
      />
    );
    // eslint-disable-next-line 
  }, [controls])
};

export default SingleFieldAutoUpdate;
