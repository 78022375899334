import axiosPackage, { AxiosResponse, Canceler } from 'axios';
import { Dispatch } from 'redux';

import axios, { getAxiosErrorResponseData } from '../../axios';
import { IProduction, IProductionAction, IProductionDimension } from '../../interfaces';
import { IProductionDimensionExpense, ISalaryImport, ISalaryImportTransfer } from './../../interfaces/domain';
import { ActionTypes } from './actionTypes';

let cancel:Canceler;

export const clearProductionsState = ():IProductionAction => {
    return {
        type: ActionTypes.CLEAR_PRODUCTIONS_STATE
    };
};

export const findProductionsStart = ():IProductionAction => {
    return {
        type: ActionTypes.FIND_PRODUCTIONS_START
    };
};

export const findProductionsSuccess = (productions:Array<IProduction>):IProductionAction => {
    return {
        type: ActionTypes.FIND_PRODUCTIONS_SUCCESS,
        productions
    };
};

export const findProductionsFail = (error:string):IProductionAction => {
    return {
        type: ActionTypes.FIND_PRODUCTIONS_FAIL,
        error
    }
};

export const findProductions = (archived: boolean) => {
    return async (dispatch:Dispatch) => {
        dispatch(findProductionsStart());
        try {
            const res = await axios.get(`/json/productions/list?archived=${archived}`);
            const productions = res.data;
            dispatch(findProductionsSuccess(productions));
        } catch (err) {
            dispatch(findProductionsFail(getAxiosErrorResponseData(err)));
        }
        
    };
};

export const getProductionStart = ():IProductionAction => {
    return {
        type: ActionTypes.GET_PRODUCTION_START
    };
};

export const getProductionSuccess = (production:IProduction):IProductionAction => {
    return {
        type: ActionTypes.GET_PRODUCTION_SUCCESS,
        production
    };
};

export const getProductionFail = (error:string):IProductionAction => {
    return {
        type: ActionTypes.GET_PRODUCTION_FAIL,
        error
    }
};

export const getProduction = (id:string) => {
    return async (dispatch:Dispatch) => {
        dispatch(getProductionStart());
        try {
            const res = await axios.get(`/json/productions/get?id=${id}`);
            const production = res.data;
            dispatch(getProductionSuccess(production));    
        } catch ( err ) {
            dispatch(getProductionFail(getAxiosErrorResponseData(err)));
        }
        
    };
};

export const copyProductionStart = ():IProductionAction => {
    return {
        type: ActionTypes.COPY_PRODUCTION_START
    };
};

export const copyProductionSuccess = (production:IProduction):IProductionAction => {
    return {
        type: ActionTypes.COPY_PRODUCTION_SUCCESS,
        production
    };
};

export const copyProductionFail = (error:string):IProductionAction => {
    return {
        type: ActionTypes.COPY_PRODUCTION_FAIL,
        error
    }
};

export const copyProduction = (id:string) => {
    return async (dispatch:Dispatch) => {
        dispatch(copyProductionStart());
        try {
            const res = await axios.get(`/json/productions/copy?id=${id}`);
            const production = res.data;
            dispatch(copyProductionSuccess(production));    
            return production;
        } catch (err) {
            dispatch(copyProductionFail(getAxiosErrorResponseData(err)));
            return null;
        }
        
    };
};

export const deleteProductionStart = ():IProductionAction => {
    return {
        type: ActionTypes.DELETE_PRODUCTION_START
    };
};

export const deleteProductionSuccess = ():IProductionAction => {
    return {
        type: ActionTypes.DELETE_PRODUCTION_SUCCESS
    };
};

export const deleteProductionFail = (error:string):IProductionAction => {
    return {
        type: ActionTypes.DELETE_PRODUCTION_FAIL,
        error
    }
};

export const deleteProduction = (id:string) => {
    return async (dispatch:Dispatch) => {
        dispatch(deleteProductionStart());
        try {
            const success = await axios.delete(`/json/productions/delete?id=${id}`);
            dispatch(deleteProductionSuccess());    
            return success;
        } catch (err) {
            dispatch(deleteProductionFail(getAxiosErrorResponseData(err)));
            return null;
        }
        
    };
};

export const createProductionStart = ():IProductionAction => {
    return {
        type: ActionTypes.CREATE_PRODUCTION_START
    };
};

export const createProductionSuccess = ():IProductionAction => {
    return {
        type: ActionTypes.CREATE_PRODUCTION_SUCCESS
    };
};

export const createProductionFail = (error:string):IProductionAction => {
    return {
        type: ActionTypes.CREATE_PRODUCTION_FAIL,
        error
    }
};

interface IAxiosError {
    response: AxiosResponse<any> 
}

export const createProduction = () => {
    return async (dispatch:Dispatch) => {
        dispatch(createProductionStart());
        try {
            const resp = await axios.post(`/json/productions/create`);
            const production:IProduction = resp.data;
            dispatch(createProductionSuccess());    
            return production.id;
        } catch (err) {
            dispatch(createProductionFail(getAxiosErrorResponseData(err)));
        }
    };
};

export const updateProductionPropertyStart = ():IProductionAction => {
    return {
        type: ActionTypes.UPDATE_PRODUCTION_PROPERTY_START
    };
};

export const updateProductionPropertySuccess = (production:IProduction):IProductionAction => {
    return {
        type: ActionTypes.UPDATE_PRODUCTION_PROPERTY_SUCCESS,
        production
    };
};

export const updateProductionPropertyFail = (error:string):IProductionAction => {
    return {
        type: ActionTypes.UPDATE_PRODUCTION_PROPERTY_FAIL,
        error
    }
};

export const updateProductionProperty = (id:string, propertyName: string, value: string) => {
    return async (dispatch:Dispatch) => {
        if(cancel) {
            cancel();
        }
        dispatch(updateProductionPropertyStart());
        try {
            const resp = await axios.put(`/json/productions/updateproperty?id=${id}`, {propertyName, [propertyName]: value}, {
                cancelToken: new axiosPackage.CancelToken((c) => {
                    cancel = c;
                })
            });
            const production:IProduction = resp.data;
            dispatch(updateProductionPropertySuccess(production));    
        } catch (err) {
            if (axiosPackage.isCancel(err)) {
                return;
            }
            dispatch(updateProductionPropertyFail(getAxiosErrorResponseData(err)));
        }
        
    };
};

export const saveProductionDimensionStart = ():IProductionAction => {
    return {
        type: ActionTypes.SAVE_PRODUCTION_DIMENSION_START
    };
};

export const saveProductionDimensionSuccess = (production:IProduction):IProductionAction => {
    return {
        type: ActionTypes.SAVE_PRODUCTION_DIMENSION_SUCCESS,
        production
    };
};

export const saveProductionDimensionFail = (error:string):IProductionAction => {
    return {
        type: ActionTypes.SAVE_PRODUCTION_DIMENSION_FAIL,
        error
    }
};

export const saveProductionDimension = (productionId:string, dimension:IProductionDimension) => {
    return async (dispatch:Dispatch) => {
        dispatch(saveProductionDimensionStart());
        try {
            const resp = await axios.post(`/json/productions/dimension/save?id=${productionId}`, dimension);
            const production:IProduction = resp.data;
            dispatch(saveProductionDimensionSuccess(production));    
            return true;
        } catch (err) {
            dispatch(saveProductionDimensionFail(getAxiosErrorResponseData(err)));
            return false;
        }
        
    };
};

export const updateProductionDimensionStart = ():IProductionAction => {
    return {
        type: ActionTypes.UPDATE_PRODUCTION_DIMENSION_START
    };
};

export const updateProductionDimensionSuccess = (production:IProduction):IProductionAction => {
    return {
        type: ActionTypes.UPDATE_PRODUCTION_DIMENSION_SUCCESS,
        production
    };
};

export const updateProductionDimensionFail = (error:string):IProductionAction => {
    return {
        type: ActionTypes.UPDATE_PRODUCTION_DIMENSION_FAIL,
        error
    }
};

export const updateProductionDimension = (productionId:string, dimension:IProductionDimension) => {
    return async (dispatch:Dispatch) => {
        dispatch(updateProductionDimensionStart());
        try {
            const resp = await axios.post(`/json/productions/dimension/update?id=${productionId}`, dimension);
            const production:IProduction = resp.data;
            dispatch(updateProductionDimensionSuccess(production));    
            return true;
        } catch (err) {
            dispatch(updateProductionDimensionFail(getAxiosErrorResponseData(err)));
            return false;
        }
        
    };
};

export const updateProductionDimensionPropertyStart = ():IProductionAction => {
    return {
        type: ActionTypes.UPDATE_PRODUCTION_DIMENSION_PROPERTY_START
    };
};

export const updateProductionDimensionPropertySuccess = (production:IProduction):IProductionAction => {
    return {
        type: ActionTypes.UPDATE_PRODUCTION_DIMENSION_PROPERTY_SUCCESS,
        production
    };
};

export const updateProductionDimensionPropertyFail = (error:string):IProductionAction => {
    return {
        type: ActionTypes.UPDATE_PRODUCTION_DIMENSION_PROPERTY_FAIL,
        error
    }
};


export const updateProductionDimensionProperty = (productionId: string, id:string, propertyName: string, value: string) => {
    return async (dispatch:Dispatch) => {
        if(cancel) {
            cancel();
        }
        dispatch(updateProductionDimensionPropertyStart());
        try {
            const resp = await axios.put(`/json/productions/dimension/updateproperty?id=${productionId}`, {id, propertyName, [propertyName]: value}, {
                cancelToken: new axiosPackage.CancelToken((c) => {
                    cancel = c;
                })
            });
            const production:IProduction = resp.data;
            dispatch(updateProductionDimensionPropertySuccess(production));    
        } catch (err) {
            if (axiosPackage.isCancel(err)) {
                return;
            }
            dispatch(updateProductionDimensionPropertyFail(getAxiosErrorResponseData(err)));
        }
        
    };
};


export const generateProductionDimensionsStart = ():IProductionAction => {
    return {
        type: ActionTypes.GENERATE_PRODUCTION_DIMENSIONS_START
    };
};

export const generateProductionDimensionsSuccess = (production:IProduction):IProductionAction => {
    return {
        type: ActionTypes.GENERATE_PRODUCTION_DIMENSIONS_SUCCESS,
        production
    };
};

export const generateProductionDimensionsFail = (error:string):IProductionAction => {
    return {
        type: ActionTypes.GENERATE_PRODUCTION_DIMENSIONS_FAIL,
        error
    }
};

export const generateProductionDimensions = (productionId:string) => {
    return async (dispatch:Dispatch):Promise<boolean> => {
        dispatch(generateProductionDimensionsStart());
        try {
            const resp = await axios.post(`/json/productions/dimension/generate?id=${productionId}`);
            const production:IProduction = resp.data;
            dispatch(generateProductionDimensionsSuccess(production));    
            return true;
        } catch (err) {
            dispatch(generateProductionDimensionsFail(getAxiosErrorResponseData(err)));
            return false;
        }
    };
};

export const deleteProductionDimensionStart = ():IProductionAction => {
    return {
        type: ActionTypes.DELETE_PRODUCTION_DIMENSION_START
    };
};

export const deleteProductionDimensionSuccess = (production:IProduction):IProductionAction => {
    return {
        type: ActionTypes.DELETE_PRODUCTION_DIMENSION_SUCCESS,
        production
    };
};

export const deleteProductionDimensionFail = (error:string):IProductionAction => {
    return {
        type: ActionTypes.DELETE_PRODUCTION_DIMENSION_FAIL,
        error
    }
};

export const deleteProductionDimension = (productionId:string, id:string) => {
    return async (dispatch:Dispatch) => {
        dispatch(deleteProductionDimensionStart());
        try {
            const resp = await axios.delete(`/json/productions/dimension/delete?productionId=${productionId}&id=${id}`);
            const production:IProduction = resp.data;
            dispatch(deleteProductionDimensionSuccess(production));    
            return true;
        } catch (err) {
            dispatch(deleteProductionDimensionFail(getAxiosErrorResponseData(err)));
            return false;
        }
    };
};

export const findProductionDimensionExpensesStart = ():IProductionAction => {
    return {
        type: ActionTypes.FIND_PRODUCTION_DIMENSION_EXPENSES_START
    };
};

export const findProductionDimensionExpensesSuccess = (expenses:Array<IProductionDimensionExpense>):IProductionAction => {
    return {
        type: ActionTypes.FIND_PRODUCTION_DIMENSION_EXPENSES_SUCCESS,
        expenses
    };
};

export const findProductionDimensionExpensesFail = (error:string):IProductionAction => {
    return {
        type: ActionTypes.FIND_PRODUCTION_DIMENSION_EXPENSES_FAIL,
        error
    }
};

export const findProductionDimensionExpenses = (dimensionId: string) => {
    return async (dispatch:Dispatch) => {
        dispatch(findProductionDimensionExpensesStart());
        try {
            const res = await axios.get(`/json/productions/dimension/expenses/list?id=${dimensionId}`);
            const expenses = res.data as Array<IProductionDimensionExpense>;
            dispatch(findProductionDimensionExpensesSuccess(expenses));
        } catch (err) {
            dispatch(findProductionDimensionExpensesFail(getAxiosErrorResponseData(err)));
        }
        
    };
};


export const searchProductionsStart = ():IProductionAction => {
    return {
        type: ActionTypes.SEARCH_PRODUCTIONS_START
    };
};

export const searchProductionsSuccess = (search:Array<IProduction>):IProductionAction => {
    return {
        type: ActionTypes.SEARCH_PRODUCTIONS_SUCCESS,
        search
    };
};

export const searchProductionsFail = (error:string):IProductionAction => {
    return {
        type: ActionTypes.SEARCH_PRODUCTIONS_FAIL,
        error
    }
};

export const searchProductions = (queryString:string) => {
    return async (dispatch:Dispatch) => {
        dispatch(searchProductionsStart());
        try {
            const res = await axios.get(`/json/productions/search?q=${queryString}`);
            dispatch(searchProductionsSuccess(res.data));
        } catch (err) {
            dispatch(searchProductionsFail(getAxiosErrorResponseData(err)));
        }
        
    };
};


export const uploadProductionSalariesStart = ():IProductionAction => {
    return {
        type: ActionTypes.UPLOAD_PRODUCTION_SALARIES_START
    };
};

export const uploadProductionSalariesSuccess = (salaries: Array<ISalaryImport>):IProductionAction => {
    return {
        type: ActionTypes.UPLOAD_PRODUCTION_SALARIES_SUCCESS,
        salaries
    };
};

export const uploadProductionSalariesFail = (error:string):IProductionAction => {
    return {
        type: ActionTypes.UPLOAD_PRODUCTION_SALARIES_FAIL,
        error
    }
};

export const uploadProductionSalaries = (formData:FormData) => {
    return async (dispatch:any) => {
        dispatch(uploadProductionSalariesStart());
        try {
            const res = await axios.post(`/json/productions/salaries/upload`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            const salaries = res.data as Array<ISalaryImport>;
            dispatch(uploadProductionSalariesSuccess(salaries));    
        } catch (err) {
            dispatch(uploadProductionSalariesFail(getAxiosErrorResponseData(err)));
        }
        
    };
};

export const importProductionSalariesStart = ():IProductionAction => {
    return {
        type: ActionTypes.IMPORT_PRODUCTION_SALARIES_START
    };
};

export const importProductionSalariesSuccess = ():IProductionAction => {
    return {
        type: ActionTypes.IMPORT_PRODUCTION_SALARIES_SUCCESS
    };
};

export const importProductionSalariesFail = (error:string):IProductionAction => {
    return {
        type: ActionTypes.IMPORT_PRODUCTION_SALARIES_FAIL,
        error
    }
};

export const importProductionSalaries = (salaries: Array<ISalaryImport>) => {
    return async (dispatch:any): Promise<boolean> => {
        dispatch(importProductionSalariesStart());
        try {
            await axios.post(`/json/productions/salaries/import`, salaries);
            dispatch(importProductionSalariesSuccess());    
            return true;
        } catch (err) {
            dispatch(importProductionSalariesFail(getAxiosErrorResponseData(err)));
            return false;
        }
    };
};

export const findProductionSalaryImportTransfersStart = ():IProductionAction => {
    return {
        type: ActionTypes.FIND_PRODUCTION_SALARY_IMPORT_TRANSFERS_START
    };
};

export const findProductionSalaryImportTransfersSuccess = (salaryTransfers:Array<ISalaryImportTransfer>):IProductionAction => {
    return {
        type: ActionTypes.FIND_PRODUCTION_SALARY_IMPORT_TRANSFERS_SUCCESS,
        salaryTransfers
    };
};

export const findProductionSalaryImportTransfersFail = (error:string):IProductionAction => {
    return {
        type: ActionTypes.FIND_PRODUCTION_SALARY_IMPORT_TRANSFERS_FAIL,
        error
    }
};

export const findProductionSalaryImportTransfers = () => {
    return async (dispatch:Dispatch) => {
        dispatch(findProductionSalaryImportTransfersStart());
        try {
            const res = await axios.get("/json/productions/salaries/transfers/list");
            const transfers = res.data;
            dispatch(findProductionSalaryImportTransfersSuccess(transfers));
        } catch (err) {
            dispatch(findProductionSalaryImportTransfersFail(getAxiosErrorResponseData(err)));
        }
        
    };
};


export const deleteProductionSalaryImportTransferStart = ():IProductionAction => {
    return {
        type: ActionTypes.DELETE_PRODUCTION_SALARY_IMPORT_TRANSFER_START
    };
};

export const deleteProductionSalaryImportTransferSuccess = ():IProductionAction => {
    return {
        type: ActionTypes.DELETE_PRODUCTION_SALARY_IMPORT_TRANSFER_SUCCESS
    };
};

export const deleteProductionSalaryImportTransferFail = (error:string):IProductionAction => {
    return {
        type: ActionTypes.DELETE_PRODUCTION_SALARY_IMPORT_TRANSFER_FAIL,
        error
    }
};

export const deleteProductionSalaryImportTransfers = (id:string) => {
    return async (dispatch:Dispatch) => {
        dispatch(deleteProductionSalaryImportTransferStart());
        try {
            await axios.delete(`/json/productions/salaries/transfers/delete?id=${id}`);
            dispatch(deleteProductionSalaryImportTransferSuccess());
            return true;
        } catch (err) {
            dispatch(deleteProductionSalaryImportTransferFail(getAxiosErrorResponseData(err)));
            return false;
        }
        
    };
};

export const clearProductionSalariesState = ():IProductionAction => {
    return {
        type: ActionTypes.CLEAR_PRODUCTION_SALARIES_STATE
    };
};