import { EProductionDimensionExpenseSource } from './../enums/production-dimension-expense';

interface IExpenseSource {
  id: EProductionDimensionExpenseSource;
  label: string;
}

export const expenseSources:Array<IExpenseSource> = [
  {id: EProductionDimensionExpenseSource.PROCOUNTOR, label: "Procountor"},
  {id: EProductionDimensionExpenseSource.MANUAL, label: "Manual"},
  {id: EProductionDimensionExpenseSource.CSV_TALENOM, label: "Talenom"},
  {id: EProductionDimensionExpenseSource.CSV_SALARIES, label: "Salaries"}
];

export const getSource = (id: EProductionDimensionExpenseSource):IExpenseSource => {
  return expenseSources.find((item) => item.id === id);
}