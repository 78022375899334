import React from "react";
import { IProductionDimensionExpenseProcountor } from "../../../../interfaces";

const classes = require('./ProductionDimensionDetailProcountor.module.scss');

interface IProps {
  expense: IProductionDimensionExpenseProcountor;
}

const ProductionDimensionDetailProcountor: React.FC<IProps> = ({ expense }) => {
  return (
    <div className={classes.Grid}>
      <div className={classes.ReceiptDate}>{expense.receiptDate}</div>
      <div className={classes.ReceiptName}>{expense.receiptName}</div>
      <div className={classes.Value}>{expense.value} €</div>
      <div className={classes.Description}>
        <i>{expense.description}</i>
      </div>
    </div>
  );
};

export default ProductionDimensionDetailProcountor;
