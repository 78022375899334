import axios, { AxiosError } from 'axios'
import jstz from 'jstz';

const hostname = window && window.location && window.location.hostname;

export const getAxiosErrorResponseData = (err: any) => {
    const error = err as AxiosError<string>;
    return (error.response && error.response.data) || 'Tuntematon virhe.';
}

let apiPath = '/';
let withCredentials = false;
if(hostname === 'localhost') {
    apiPath = 'http://localhost:8080/';
    withCredentials = true;
}

const instance = axios.create({
    baseURL: apiPath,
    withCredentials: withCredentials
});

instance.interceptors.request.use(config => {
    const timezone = jstz.determine();
    config.headers['Timezone'] = timezone.name();
    return config;
});


instance.interceptors.response.use(config => config, (err) => {
    if (axios.isCancel(err)) {
        return Promise.reject(err);
    }

    const response = err.response;
    if(response && response.status === 500) {
        response.data = 'Server error! Please contact support.';
    }
    return Promise.reject({ response });
});

export default instance;