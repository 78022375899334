import React from "react";
import SingleFieldAutoUpdate from "../../SingleFieldAutoUpdate/SingleFieldAutoUpdate";

const classes = require("./ProductionDimensionNote.module.scss");

interface IProps {
  canEdit: boolean;
  notes: string;
  id: string;
}

const ProductionDimensionNote: React.FC<IProps> = ({ canEdit, notes, id }) => {
  return (
    <div className={classes.Container}>
      {canEdit ? (
        <SingleFieldAutoUpdate
          initialControls={{
            notes: {
              elementType: "textarea",
              elementConfig: {
                type: "text"
              },
              value: notes,
              valid: true
            }
          }}
          id={id}
        />
      ) : (
        <p>{notes}</p>
      )}
    </div>
  );
};

export default ProductionDimensionNote;
