import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import { dimensionUnits, getDimensionUnit } from '../../../../data/dimension-unit';
import { IProductionDimension, IUser } from '../../../../interfaces';
import SingleFieldAutoUpdate from '../../../SingleFieldAutoUpdate/SingleFieldAutoUpdate';
import ProductionDimensionNote from '../../ProductionDimensionNote/ProductionDimensionNote';
import { EUserRole } from '../../../../enums/user-role';
import { hasRoles } from '../../../../shared/utility';

const classes = require("./ProductionBudgetCategory.module.scss");

interface IProps {
  dimensions: Array<IProductionDimension>;
  title?: string;
  subTitle?: string;
  emptyRow?: boolean;
  canEdit: boolean;
  budgetTotal: string;
  budgetTotalTitle?: string;
  subBudgetTotal?: string;
  subBudgetTotalTitle?: string;
  currentUser: IUser;
  onEditDimension: (dimension: IProductionDimension) => void;
  onDeleteDimension: (dimension: IProductionDimension) => void;
}

const ProductionBudgetCategory: React.FC<IProps> = ({
  dimensions,
  title,
  subTitle,
  emptyRow,
  canEdit,
  budgetTotal,
  budgetTotalTitle,
  subBudgetTotal,
  subBudgetTotalTitle,
  onEditDimension,
  onDeleteDimension,
  currentUser
}) => {
  if (!dimensions || !dimensions.length) {
    return null;
  }

  return (
    <React.Fragment>
      <thead>
        {emptyRow && (
          <tr className={classes.EmptyRow}>
            <td colSpan={6} />
          </tr>
        )}
        {title && (
          <tr className={classes.Title}>
            <th colSpan={6}>{title}</th>
          </tr>
        )}
        {subTitle && (
          <tr>
            <th colSpan={6}>{subTitle}</th>
          </tr>
        )}
      </thead>
      <tbody>
        {dimensions.map(item => (
          <tr key={item.id} className={classes.DimensionRow}>
            <td>
              <div className={classes.DimensionActionsWrapper}>
                <div>
                  {item.accountNumber} {item.name}
                </div>
                {hasRoles(currentUser, EUserRole.ADMIN) && !canEdit && (
                  <div className={classes.DimensionActions}>
                    <span title="Edit" onClick={() => onEditDimension(item)}>
                      <FontAwesomeIcon icon={faEdit} />
                    </span>
                    <span
                      title="Delete"
                      onClick={() => onDeleteDimension(item)}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </span>
                  </div>
                )}
              </div>
            </td>
            <td>
              {canEdit ? (
                <SingleFieldAutoUpdate
                  initialControls={{
                    unit: {
                      elementType: "select",
                      elementConfig: {},
                      valid: false,
                      touched: false,
                      value: item.unit,
                      options: dimensionUnits
                    }
                  }}
                  id={item.id}
                />
              ) : (
                getDimensionUnit(item.unit).label
              )}
            </td>
            <td>
              {canEdit ? (
                <SingleFieldAutoUpdate
                  initialControls={{
                    amount: {
                      elementType: "input",
                      elementConfig: {
                        type: "number",
                        selectOnFocus: true
                      },
                      value: item.amount,
                      valid: true
                    }
                  }}
                  id={item.id}
                />
              ) : (
                item.amount
              )}
            </td>
            <td>
              {canEdit ? (
                <SingleFieldAutoUpdate
                  initialControls={{
                    price: {
                      elementType: "input",
                      elementConfig: {
                        type: "number",
                        selectOnFocus: true
                      },
                      value: item.price,
                      valid: true
                    }
                  }}
                  id={item.id}
                />
              ) : (
                `${item.priceDisplay} €`
              )}
            </td>
            <td>{item.budget} €</td>
            <td>
                <ProductionDimensionNote canEdit={canEdit} id={item.id} notes={item.notes} />
            </td>
          </tr>
        ))}
        {subBudgetTotal && (
          <tr>
            <th colSpan={4}>{subBudgetTotalTitle ? subBudgetTotalTitle : 'Total'}</th>
            <th>{subBudgetTotal} €</th>
            <th></th>
          </tr>
        )}
        {budgetTotal && (
          <tr>
            <th colSpan={4}>{budgetTotalTitle ? budgetTotalTitle: 'Total'}</th>
            <th>{budgetTotal} €</th>
            <th></th>
          </tr>
        )}
      </tbody>
    </React.Fragment>
  );
};

export default React.memo(ProductionBudgetCategory, (prevProps, nextProps) => {
  return (
    prevProps.budgetTotal === nextProps.budgetTotal &&
    prevProps.subBudgetTotal === nextProps.subBudgetTotal &&
    prevProps.canEdit === nextProps.canEdit &&
    JSON.stringify(prevProps.dimensions) ===
      JSON.stringify(nextProps.dimensions)
  );
});
