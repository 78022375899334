import React, { useEffect } from "react";

const classes = require("./Backdrop.module.scss");

interface IProps {
  show: boolean;
  clicked: () => void;
}

const Backdrop: React.FC<IProps> = props => {
  const { show } = props;

  useEffect(() => {
    if (show) {
      document.querySelector("body").classList.add("overflow-hidden");
    } else {
      document.querySelector("body").classList.remove("overflow-hidden");
    }
  }, [show]);

  let containerClasses = [classes.Backdrop];

  if (show) {
    containerClasses.push(classes.Active);
  }

  return <div className={containerClasses.join(" ")} onClick={props.clicked} />;
};

export default Backdrop;
