import React from "react";

import {
  IProductionDimension,
  IProductionTotals
} from "../../../../interfaces";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import SingleFieldAutoUpdate from "../../../SingleFieldAutoUpdate/SingleFieldAutoUpdate";
import { zeroIfNull } from "../../../../shared/utility";
import ProductionDimensionNote from "../../ProductionDimensionNote/ProductionDimensionNote";

const classes = require("./ProductionTotalCategory.module.scss");

interface IProps {
  dimensions: Array<IProductionDimension>;
  title?: string;
  subTitle?: string;
  emptyRow?: boolean;
  totals?: IProductionTotals;
  totalsTitle?: string;
  canEdit: boolean;
  subTotals?: IProductionTotals;
  subTotalsTitle?: string;
  onShowDimensionDetails: (dimension: IProductionDimension) => void;
}

const ProductionTotalCategory: React.FC<IProps> = ({
  dimensions,
  title,
  subTitle,
  emptyRow,
  totals,
  totalsTitle,
  subTotals,
  subTotalsTitle,
  onShowDimensionDetails,
  canEdit
}) => {
  if (!dimensions || !dimensions.length) {
    return null;
  }

  return (
    <React.Fragment>
      <thead>
        {emptyRow && (
          <tr className={classes.EmptyRow}>
            <td colSpan={7} />
          </tr>
        )}
        {title && (
          <tr className={classes.Title}>
            <th colSpan={7}>{title}</th>
          </tr>
        )}
        {subTitle && (
          <tr>
            <th colSpan={7}>{subTitle}</th>
          </tr>
        )}
      </thead>
      <tbody>
        {dimensions.map(item => (
          <tr key={item.id} className={classes.DimensionRow}>
            <td>
              {item.accountNumber} {item.name}
            </td>
            <td>{item.budget} €</td>
            <td>
              <div className={classes.ExpensesCol}>
                {item.expenseValueTotalDisplay} €
                {(item.canViewExpenses) && (
                  <span onClick={() => onShowDimensionDetails(item)}>
                    <FontAwesomeIcon icon={faEye} />
                  </span>
                )}
              </div>
            </td>
            <td>
              {canEdit ? (
                <SingleFieldAutoUpdate
                  initialControls={{
                    estimate: {
                      elementType: "input",
                      elementConfig: {
                        type: "number",
                        selectOnFocus: true
                      },
                      value: item.estimate,
                      valid: true
                    }
                  }}
                  id={item.id}
                />
              ) : (
                `${zeroIfNull(item.estimateDisplay)} €`
              )}
            </td>
            <td>{item.totalPrice} €</td>
            <td>{item.statusPrice} €</td>
            <td>
              <ProductionDimensionNote canEdit={canEdit} id={item.id} notes={item.notes} />
            </td>
          </tr>
        ))}
        {subTotals && (
          <tr>
            <th>{subTotalsTitle ? subTotalsTitle : 'Total'}</th>
            <th>{subTotals.budget} €</th>
            <th>{subTotals.expensesValue} €</th>
            <th>{subTotals.estimate} €</th>
            <th>{subTotals.total} €</th>
            <th>{subTotals.statusDisplay} €</th>
            <th></th>
          </tr>
        )}
        {totals && (
          <tr>
            <th>{totalsTitle ? totalsTitle : 'Total'}</th>
            <th>{totals.budget} €</th>
            <th>{totals.expensesValue} €</th>
            <th>{totals.estimate} €</th>
            <th>{totals.total} €</th>
            <th>{totals.statusDisplay} €</th>
            <th></th>
          </tr>
        )}
      </tbody>
    </React.Fragment>
  );
};

export default ProductionTotalCategory;
