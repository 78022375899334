import { AxiosResponse } from 'axios';
import { Dispatch } from 'redux';

import axios from '../../axios';
import { IProcountorLedgerReceiptsTransfer, IProcountorLedgerReceipt } from '../../interfaces';
import { IProcountorAction } from './../../interfaces/state';
import { ActionTypes } from './actionTypes';

export const findLedgerReceiptsTransfersStart = ():IProcountorAction => {
    return {
        type: ActionTypes.FIND_LEDGER_RECIEPTS_TRANSFERS_START
    };
};

export const findLedgerReceiptsTransfersSuccess = (ledgerReceiptsTransfers:Array<IProcountorLedgerReceiptsTransfer>):IProcountorAction => {
    return {
        type: ActionTypes.FIND_LEDGER_RECIEPTS_TRANSFERS_SUCCESS,
        ledgerReceiptsTransfers
    };
};

export const findLedgerReceiptsTransfersFail = (error:string):IProcountorAction => {
    return {
        type: ActionTypes.FIND_LEDGER_RECIEPTS_TRANSFERS_FAIL,
        error
    }
};

export const findLedgerReceiptsTransfers = () => {
    return async (dispatch:Dispatch) => {
        dispatch(findLedgerReceiptsTransfersStart());
        try {
            const res = await axios.get("/json/procountor/ledgerreceipts/transfers/list");
            const transfers = res.data;
            dispatch(findLedgerReceiptsTransfersSuccess(transfers));
            return transfers;
        } catch ({ response }) {
            dispatch(findLedgerReceiptsTransfersFail((response as AxiosResponse).data));
        }
        return null;
    };
};


export const createLedgerReceiptsTransferStart = ():IProcountorAction => {
    return {
        type: ActionTypes.CREATE_LEDGER_RECIEPTS_TRANSFER_START
    };
};

export const createLedgerReceiptsTransferSuccess = ():IProcountorAction => {
    return {
        type: ActionTypes.CREATE_LEDGER_RECIEPTS_TRANSFER_SUCCESS,
    };
};

export const createLedgerReceiptsTransferFail = (error:string):IProcountorAction => {
    return {
        type: ActionTypes.CREATE_LEDGER_RECIEPTS_TRANSFER_FAIL,
        error
    }
};

export const createLedgerReceiptsTransfer = (data: any) => {
    return async (dispatch:Dispatch) => {
        dispatch(createLedgerReceiptsTransferStart());
        try {
            await axios.post("/json/procountor/ledgerreceipts/transfers/create", data);
            dispatch(createLedgerReceiptsTransferSuccess());
            return true;
        } catch ({ response }) {
            dispatch(createLedgerReceiptsTransferFail((response as AxiosResponse).data));
            return false;
        }
    };
};

export const deleteLedgerReceiptsTransferStart = ():IProcountorAction => {
    return {
        type: ActionTypes.DELETE_LEDGER_RECIEPTS_TRANSFER_START
    };
};

export const deleteLedgerReceiptsTransferSuccess = ():IProcountorAction => {
    return {
        type: ActionTypes.DELETE_LEDGER_RECIEPTS_TRANSFER_SUCCESS,
    };
};

export const deleteLedgerReceiptsTransferFail = (error:string):IProcountorAction => {
    return {
        type: ActionTypes.DELETE_LEDGER_RECIEPTS_TRANSFER_FAIL,
        error
    }
};

export const deleteLedgerReceiptsTransfer = (id: string) => {
    return async (dispatch:Dispatch) => {
        dispatch(deleteLedgerReceiptsTransferStart());
        try {
            await axios.delete(`/json/procountor/ledgerreceipts/transfers/delete?id=${id}`);
            dispatch(deleteLedgerReceiptsTransferSuccess());
            return true;
        } catch ({ response }) {
            dispatch(deleteLedgerReceiptsTransferFail((response as AxiosResponse).data));
            return false;
        }
    };
};

export const findPendingLedgerReceiptsStart = ():IProcountorAction => {
    return {
        type: ActionTypes.FIND_PENDING_LEDGER_RECEIPTS_START
    };
};

export const findPendingLedgerReceiptsSuccess = (ledgerReceipts:IProcountorLedgerReceipt[]):IProcountorAction => {
    return {
        type: ActionTypes.FIND_PENDING_LEDGER_RECEIPTS_SUCCESS,
        ledgerReceipts
    };
};

export const findPendingLedgerReceiptsFail = (error:string):IProcountorAction => {
    return {
        type: ActionTypes.FIND_PENDING_LEDGER_RECEIPTS_FAIL,
        error
    }
};

export const findPendingLedgerReceipts = () => {
    return async (dispatch:Dispatch) => {
        dispatch(findPendingLedgerReceiptsStart());
        try {
            const res = await axios.get<IProcountorLedgerReceipt[]>("/json/procountor/ledgerreceipts/pending/list");
            const ledgerReceipts = res.data;
            dispatch(findPendingLedgerReceiptsSuccess(ledgerReceipts));
            return ledgerReceipts;
        } catch ({ response }) {
            dispatch(findPendingLedgerReceiptsFail((response as AxiosResponse).data));
        }
        return null;
    };
};

