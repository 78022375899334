import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { Alert, Button, Table } from "reactstrap";
import CustomDialog from "../../components/UI/CustomDialog/CustomDialog";
import { getProductionGroup } from "../../data/production-group";
import { EUserRole } from "../../enums/user-role";
import ContentBox, { IContentBoxAction } from "../../hoc/ContentBox/ContentBox";
import Scrollable from "../../hoc/Scrollable/Scrollable";
import { IAppState, IProduction, IUser } from "../../interfaces";
import { hasRoles } from "../../shared/utility";
import * as actions from "../../store/actions";
import { EProductionTab } from "./Production/Production";

const classes = require("./Productions.module.scss");

interface IStateProps {
  currentUser: IUser;
  loading: boolean;
  productions: Array<IProduction>;
  error: string;
}

interface IDispatchProps {
  onCreateProduction: () => Promise<string>;
  onFindProductions: (archived: boolean) => Promise<void>;
  onCopyProduction: (id: string) => Promise<IProduction>;
  onUpdateProductionProperty: (
    id: string,
    controlName: string,
    value: string
  ) => Promise<void>;
  onDeleteProduction: (id: string) => Promise<boolean>;
}

interface IProps extends IStateProps, IDispatchProps, RouteComponentProps {}

const defaultDialog = {
  open: false,
  onOk: () => {},
};

const Productions: React.FC<IProps> = (props) => {
  const [showArchived, setShowArchived] = useState(false);
  const [deleteProductionDialog, setDeleteProductionDialog] =
    useState(defaultDialog);

  const {
    history,
    productions,
    error,
    currentUser,
    onFindProductions,
    onCreateProduction,
    onCopyProduction,
    onUpdateProductionProperty,
    onDeleteProduction,
  } = props;

  useEffect(() => {
    onFindProductions(showArchived);
  }, [onFindProductions, showArchived]);

  let headers = ["Production"];

  if (
    hasRoles(
      currentUser,
      EUserRole.ADMIN,
      EUserRole.ADMIN_VIEWER,
      EUserRole.EXECUTIVE_TEAM
    )
  ) {
    headers = [
      ...headers,
      "Group",
      "Sales budget",
      "Orig. Producer's cost",
      "Forecasted cost",
      "Margin eur",
      "Production mar",
      /*
      "Sales adjustment",
      "Sales in ACC.",
      "Cost in ACC.",
      "Margin EUR in ACC.",
      "Margin % in ACC.",
      */
    ];
  }

  if (hasRoles(currentUser, EUserRole.ADMIN)) {
    headers = [...headers, ""];
  }

  const onAddProduction = async () => {
    const id = await onCreateProduction();
    onOpenProduction(id);
  };
  const onOpenProduction = (id: string) => {
    history.push(`/productions/${id}/${EProductionTab.BUDGET}`);
  };

  const onCopyHandler = async (
    event: React.MouseEvent<HTMLButtonElement>,
    id: string
  ) => {
    event.stopPropagation();
    const production = await onCopyProduction(id);
    if (production && production.id) {
      onOpenProduction(production.id);
    }
  };

  const onArchiveProduction = async (
    event: React.MouseEvent<HTMLButtonElement>,
    id: string,
    value: string
  ) => {
    event.stopPropagation();
    await onUpdateProductionProperty(id, "archived", value);
    onFindProductions(showArchived);
  };

  const deleteProductionHandler = (
    event: React.MouseEvent<HTMLButtonElement>,
    id: string
  ) => {
    event.stopPropagation();

    setDeleteProductionDialog({
      open: true,
      onOk: async () => {
        setDeleteProductionDialog(defaultDialog);
        const success = await onDeleteProduction(id);
        if (success) {
          onFindProductions(showArchived);
        }
      },
    });
  };

  const onToggleArchived = () => {
    setShowArchived(!showArchived);
  };

  let actions: Array<IContentBoxAction> = [];

  if (hasRoles(currentUser, EUserRole.ADMIN)) {
    actions = [...actions, { text: "Add", clicked: onAddProduction }];
  }

  let title = "Active productions";

  if (showArchived) {
    title = "Archived productions";
  }

  return (
    <React.Fragment>
      {error && (
        <Alert className="error-message" color="danger">
          {error}
        </Alert>
      )}
      <CustomDialog
        loading={false}
        onOk={deleteProductionDialog.onOk}
        onCancel={() => setDeleteProductionDialog(defaultDialog)}
        open={deleteProductionDialog.open}
        title="Delete production"
      >
        Are you sure you wan't to delete production? It will be permanent.
      </CustomDialog>
      <Scrollable>
        <ContentBox title={title} loading={props.loading} actions={actions}>
          <div className={classes.Filters}>
            <Button
              color="secondary"
              size="sm"
              active={showArchived}
              onClick={onToggleArchived}
            >
              Toggle archived
            </Button>
          </div>

          {productions ? (
            <Table responsive className={classes.Table} striped bordered>
              <thead>
                <tr>
                  {headers.map((header) => (
                    <th key={header} className={classes.Header}>
                      {header}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {productions &&
                  productions.map((production, i) => {
                    const productionGroup = getProductionGroup(
                      production.group
                    );
                    return (
                      <tr
                        className={classes.TableRow}
                        onClick={() => onOpenProduction(production.id)}
                        key={production.id}
                      >
                        <td className="nowrap">{production.nameEn}</td>
                        {hasRoles(
                          currentUser,
                          EUserRole.ADMIN,
                          EUserRole.ADMIN_VIEWER,
                          EUserRole.EXECUTIVE_TEAM
                        ) && (
                          <React.Fragment>
                            <td>
                              {productionGroup ? productionGroup.label : null}
                            </td>
                            <td>{production.salesPriceDisplay} €</td>
                            <td>{production.totalBudgetWithExpenses} €</td>
                            <td>{production.totalEstimateWithExpenses} €</td>
                            <td>{production.marginEur} €</td>
                            <td>{production.marginPercent} %</td>
                            {/*
                              <td />
                              <td>{production.salesPriceDisplay} €</td>
                              <td>{production.accCosts} €</td>
                              <td>{production.accMarginEur} €</td>
                              <td>{production.accMarginPercent} %</td>
                            */}
                            {hasRoles(currentUser, EUserRole.ADMIN) && (
                              <td className="button-row">
                                <Button
                                  color="secondary"
                                  size="sm"
                                  onClick={(event) =>
                                    onCopyHandler(event, production.id)
                                  }
                                >
                                  Copy
                                </Button>
                                <Button
                                  color="secondary"
                                  size="sm"
                                  onClick={(event) =>
                                    onArchiveProduction(
                                      event,
                                      production.id,
                                      showArchived ? "false" : "true"
                                    )
                                  }
                                >
                                  {showArchived ? "Unarchive" : "Archive"}
                                </Button>
                                <Button
                                  color="danger"
                                  size="sm"
                                  onClick={(event) =>
                                    deleteProductionHandler(
                                      event,
                                      production.id
                                    )
                                  }
                                >
                                  Delete
                                </Button>
                              </td>
                            )}
                          </React.Fragment>
                        )}
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          ) : (
            <Alert color="info">No productions</Alert>
          )}
        </ContentBox>
      </Scrollable>
    </React.Fragment>
  );
};

const mapStateToProps = (state: IAppState): IStateProps => {
  return {
    currentUser: state.auth.currentUser,
    loading: state.productions.loading || state.productions.updating,
    productions: state.productions.productions,
    error: state.productions.error,
  };
};

const mapDispatchToProps = (dispatch: any): IDispatchProps => {
  return {
    onCreateProduction: () => dispatch(actions.createProduction()),
    onFindProductions: (archived: boolean) =>
      dispatch(actions.findProductions(archived)),
    onCopyProduction: (id) => dispatch(actions.copyProduction(id)),
    onUpdateProductionProperty: (id, propertyName, value) =>
      dispatch(actions.updateProductionProperty(id, propertyName, value)),
    onDeleteProduction: (id) => dispatch(actions.deleteProduction(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Productions);
