import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  Redirect,
  Route,
  RouteComponentProps,
  Switch,
  withRouter
} from "react-router";
import { Spinner } from "reactstrap";

import Logout from "./containers/Auth/Logout/Logout";
import Error from "./containers/Error/Error";
import Productions from "./containers/Productions/Productions";
import User from "./containers/Users/User/User";
import Users from "./containers/Users/Users";
import Layout from "./hoc/Layout/Layout";
import { IAppState, IUser } from "./interfaces";
import * as actions from "./store/actions";
import Production from "./containers/Productions/Production/Production";
import Transfers from "./containers/Transfers/Transfers";
import { hasRoles } from "./shared/utility";
import { EUserRole } from "./enums/user-role";
import PendingReceipts from "./containers/PendingReceipts/PendingReceipts";

const classes = require("./App.module.scss");

interface IDispatchProps {
  onGetCurrentUser: () => {};
  onStoreAuthRedirect: (redirectUrl: string) => {};
}

interface IStateProps {
  currentUser: IUser;
  loading: boolean;
  redirect: string;
}

interface IProps extends IDispatchProps, IStateProps, RouteComponentProps {}

const App: React.FC<IProps> = props => {
  const { onGetCurrentUser, redirect, history, currentUser } = props;

  useEffect(() => {
    onGetCurrentUser();
  }, [onGetCurrentUser]);

  useEffect(() => {
    if (redirect) {
      if (redirect.indexOf("http") === 0) {
        window.location.href = redirect;
      } else {
        history.replace(redirect);
      }
    }
  }, [redirect, history]);

  const renderAdminRoutes = () => {
    const adminRoutes = [
      <Route key="ADMIN_1" path="/users/:id" component={User} />,
      <Route key="ADMIN_2" path="/users" component={Users} />,
      <Route key="ADMIN_3" path="/transfers" component={Transfers} />,
      <Route key="ADMIN_4" path="/pending" component={PendingReceipts} />
    ];
    return adminRoutes;
  };

  let routes = null;
  if (currentUser) {
    routes = (
      <Switch>
        <Route path="/logout" component={Logout} />
        <Route path="/error/401" component={Error} />
        <Route path="/productions/:id/:tab" component={Production} />
        <Route path="/productions" component={Productions} />
        {hasRoles(currentUser, EUserRole.ADMIN) && renderAdminRoutes()} 
        <Redirect to="/productions" />
      </Switch>
    );
  } else {
    routes = (
      <Switch>
        <Route path="/error/401" component={Error} />
      </Switch>
    );
  }

  return props.loading ? (
    <div className={classes.Loading}>
      <Spinner />
    </div>
  ) : (
    <Layout>{routes}</Layout>
  );
};

const mapStateToProps = (state: IAppState): IStateProps => {
  return {
    currentUser: state.auth.currentUser,
    loading: state.auth.loading,
    redirect: state.auth.redirect
  };
};

const mapDispatchToProps = (dispatch: any): IDispatchProps => {
  return {
    onGetCurrentUser: () => dispatch(actions.getCurrentUser()),
    onStoreAuthRedirect: redirectUrl =>
      dispatch(actions.storeAuthRedirect(redirectUrl))
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(App)
);
