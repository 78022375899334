import React, { useState } from 'react';
import { NavLink as RouterNavLink, Redirect } from 'react-router-dom';
import {
  Collapse,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink,
  UncontrolledDropdown,
} from 'reactstrap';

import { IUser } from '../../interfaces';
import { hasRoles } from '../../shared/utility';
import { EUserRole } from '../../enums/user-role';
import imgLogo from '../../assets/images/mediawan-logo.png';

const classes = require("./Navigation.module.scss");

interface IProps {
  currentUser: IUser;
}

const Navigation: React.FC<IProps> = ({currentUser}) => {
  const [isOpen, setOpen] = useState(false);
  const [isLogout, setLogout] = useState(false);

  const onToggle = () => {
    setOpen(!isOpen);
  };

  const onLogout = () => {
    setLogout(true);
  };

  const navItems = [
    {
      link: "/productions",
      text: "Productions"
    },
    {
      link: "/users",
      text: "Users"
    },
    {
      link: "/transfers",
      text: "Transfers"
    },
  ];

  return (
    <Navbar color="light" light expand="md" className={classes.NavBar}>
      {isLogout && <Redirect to="/logout" />}
      {currentUser ? (
        <React.Fragment>
          <NavbarBrand tag={RouterNavLink} to="/productions" className={classes.Brand}>
            <img src={imgLogo} alt="Mediawan Finland" />
          </NavbarBrand>
          <NavbarToggler onClick={onToggle} />
          <Collapse isOpen={isOpen} navbar>
            <Nav className="ml-auto" navbar>
              {hasRoles(currentUser, EUserRole.ADMIN) && navItems.map(navItem => (
                <NavItem key={navItem.text} className={classes.NavItem}>
                  <NavLink tag={RouterNavLink} to={navItem.link}>
                    {navItem.text}
                  </NavLink>
                </NavItem>
              ))}
              {/*
              <NavItem>
                <Input
                  type="search"
                  name="search"
                  id="exampleSearch"
                  placeholder="Search"
                />
              </NavItem>
              */}
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                  {currentUser.name}
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem onClick={onLogout}>Logout GSuite</DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Collapse>
        </React.Fragment>
      ) : (
        <NavbarBrand href="/" className={classes.Brand}>
          &nbsp;
        </NavbarBrand>
      )}
    </Navbar>
  );
};

export default Navigation;
