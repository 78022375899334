import { AxiosResponse } from 'axios';
import { Dispatch } from 'redux';

import axios from '../../axios';
import { ITalenomCSVTransfer } from '../../interfaces';
import { IProcountorAction, ITalenomAction } from '../../interfaces/state';
import { ActionTypes } from './actionTypes';


type IAction = ITalenomAction;

const findTalenomTransfersStart = (): IAction => {
    return {
        type: ActionTypes.TALENOM_FIND_TRANSFERS_START
    };
};

const findTalenomTransfersSuccess = (talenomTransfers: ITalenomCSVTransfer[] ): IAction => {
    return {
        type: ActionTypes.TALENOM_FIND_TRANSFERS_SUCCESS,
        talenomTransfers
    };
};

const findTalenomTransfersFail = (error:string):IProcountorAction => {
    return {
        type: ActionTypes.TALENOM_FIND_TRANSFERS_FAIL,
        error
    }
};

export const findTalenomTransfers = () => {
    return async (dispatch:Dispatch) => {
        dispatch(findTalenomTransfersStart());
        try {
            const res = await axios.get("/json/talenom/transfers/list");
            const transfers = res.data;
            dispatch(findTalenomTransfersSuccess(transfers));
            return transfers;
        } catch ({ response }) {
            dispatch(findTalenomTransfersFail((response as AxiosResponse).data));
        }
        return null;
    };
};

const deleteTransferStart = (): IAction => {
    return {
        type: ActionTypes.DELETE_LEDGER_RECIEPTS_TRANSFER_START
    };
};

const deleteTransferSuccess = (): IAction => {
    return {
        type: ActionTypes.DELETE_LEDGER_RECIEPTS_TRANSFER_SUCCESS,
    };
};

const deleteTransferFail = (error:string): IAction => {
    return {
        type: ActionTypes.DELETE_LEDGER_RECIEPTS_TRANSFER_FAIL,
        error
    }
};

export const deleteTalenomTransfer = (id: string) => {
    return async (dispatch:Dispatch) => {
        dispatch(deleteTransferStart());
        try {
            await axios.delete(`/json/talenom/transfers/delete?id=${id}`);
            dispatch(deleteTransferSuccess());
            return true;
        } catch ({ response }) {
            dispatch(deleteTransferFail((response as AxiosResponse).data));
            return false;
        }
    };
};

