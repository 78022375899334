import React from "react";
import { Badge, Button, Alert, Spinner } from "reactstrap";

import { getSource } from "../../../data/expense-source";
import {
  IProductionDimensionExpenseProcountor,
  IProductionDimensionExpenseCSVSalaries,
  IProductionDimensionExpense,
  IProductionDimensionExpenseCSVTalenom,
} from "../../../interfaces";
import BoxBody from "../../UI/BoxBody/BoxBody";
import BoxFooter from "../../UI/BoxFooter/BoxFooter";
import { EProductionDimensionExpenseSource } from "../../../enums/production-dimension-expense";
import ProductionDimensionDetailProcountor from "./ProductionDimensionDetailProcountor/ProductionDimensionDetailProcountor";
import ProductionDimensionDetailCSVSalaries from "./ProductionDimensionDetailCSVSalaries/ProductionDimensionDetailCSVSalaries";
import ProductionDimensionDetailCSVTalenom from "./ProductionDimensionDetailTalenom/ProductionDimensionDetailTalenom";

const classes = require("./ProductionDimensionDetail.module.scss");

interface IProps {
  expenses: Array<IProductionDimensionExpense>;
  expenseExpensesTotal: string;
  expenseValueTotal: string;
  onCloseSidebar: () => void;
  loading: boolean;
  error: string;
}

const ProductionDimensionDetail: React.FC<IProps> = ({
  expenses,
  onCloseSidebar,
  expenseValueTotal,
  expenseExpensesTotal,
  loading,
  error,
}) => {
  return (
    <React.Fragment>
      <BoxBody>
        {loading ? (
          <Spinner />
        ) : error ? (
          <Alert color="danger">{error}</Alert>
        ) : (
          <React.Fragment>
            {expenses &&
              expenses.map((expense) => {
                return (
                  <div className={classes.Grid} key={expense.id}>
                    {(() => {
                      switch (expense.source) {
                        case EProductionDimensionExpenseSource.PROCOUNTOR:
                          return (
                            <ProductionDimensionDetailProcountor
                              expense={
                                expense as IProductionDimensionExpenseProcountor
                              }
                            />
                          );
                        case EProductionDimensionExpenseSource.CSV_SALARIES:
                          return (
                            <ProductionDimensionDetailCSVSalaries
                              expense={
                                expense as IProductionDimensionExpenseCSVSalaries
                              }
                            />
                          );
                        case EProductionDimensionExpenseSource.CSV_TALENOM:
                          return (
                            <ProductionDimensionDetailCSVTalenom
                              expense={
                                expense as IProductionDimensionExpenseCSVTalenom
                              }
                            />
                          );
                        default:
                          return null;
                      }
                    })()}
                    <div className={classes.Type}>
                      <small>
                        <Badge>{getSource(expense.source).label}</Badge>
                      </small>
                    </div>
                  </div>
                );
              })}
            <div className={classes.Total}>
              <div className={classes.ExpenseValueTotal}>
                {expenseValueTotal} €
              </div>
            </div>
          </React.Fragment>
        )}
      </BoxBody>
      <BoxFooter>
        <Button onClick={onCloseSidebar}>Close</Button>
      </BoxFooter>
    </React.Fragment>
  );
};

export default ProductionDimensionDetail;
