import React from 'react';
import { Badge, Button, Spinner, Table } from 'reactstrap';

import ContentBox from '../../../hoc/ContentBox/ContentBox';
import { ETalenomCSVTransferStatus, ITalenomCSVTransfer } from '../../../interfaces';

interface IProps {
  talenomTransfers: ITalenomCSVTransfer[];
  loading: boolean;
  onDelete: (id: string) => void;
}

const TransfersTalenom: React.FC<IProps> = ({
  talenomTransfers,
  loading,
  onDelete,
}) => {

  const getStatusColor = (status: ETalenomCSVTransferStatus):string =>  {
    switch(status) {
      case ETalenomCSVTransferStatus.DONE:
        return "success";
      case ETalenomCSVTransferStatus.DELETING:
        return "danger";
      default:
        return "secondary";
    }
  }

  return (
    <ContentBox title="Talenom">
      {loading ? (
        <Spinner />
      ) : talenomTransfers == null ? (
        <p>No transfers</p>
      ) : (
        <Table striped bordered>
          <thead>
            <tr>
              <th>Status</th>
              <th>Created</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {talenomTransfers != null &&
              talenomTransfers.map(transfer => (
                <tr key={transfer.id}>
                  <td><Badge color={getStatusColor(transfer.status)}>{transfer.status}</Badge></td>
                  <td>{transfer.created}</td>
                  <td className="min-width">
                    <Button
                      color="secondary"
                      size="sm"
                      onClick={() => onDelete(transfer.id)}
                    >
                      Delete
                    </Button>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      )}
    </ContentBox>
  );
};

export default TransfersTalenom;
