import React from 'react';
import { Table } from 'reactstrap';

import { createCategoriesArray } from '../../../data/dimension-category';
import { IProduction, IProductionDimension } from '../../../interfaces';
import ProductionTotalCategory from './ProductionTotalCategory/ProductionTotalCategory';

const classes = require("./ProductionTotal.module.scss");

interface IProps {
  production: IProduction;
  canEdit: boolean;
  onShowDimensionDetails: (dimension: IProductionDimension) => void;
}

const ProductionTotal: React.FC<IProps> = ({
  production,
  canEdit,
  onShowDimensionDetails
}) => {
  if (!production) return null;
  const {
    totals,
  } = production;

  const categories = createCategoriesArray(production);
  if (categories == null) return null;

  return (
    <Table responsive className={classes.Table} striped bordered>
      <thead>
        <tr>
          <th>Expenses</th>
          <th>Budget</th>
          <th>Actual expenses</th>
          <th>Future / Estimate</th>
          <th>Total</th>
          <th>Status</th>
          <th>Notes</th>
        </tr>
      </thead>
      {categories.map((item, index) => (
        <ProductionTotalCategory
          key={index}
          dimensions={item.dimensions}
          title={item.title}
          subTitle={item.subTitle}
          emptyRow={item.emptyRow}
          totals={item.totals}
          totalsTitle={item.totalsTitle}
          subTotals={item.subTotals}
          subTotalsTitle={item.subTotalsTitle}
          onShowDimensionDetails={onShowDimensionDetails}
          canEdit={canEdit}
        />
      ))}
      <tfoot>
        <tr>
          <th>Total</th>
          <th>{totals.budget} €</th>
          <th>{totals.expensesValue} €</th>
          <th>{totals.estimate} €</th>
          <th>{totals.total} €</th>
          <th>{totals.statusDisplay} €</th>
          <th></th>
        </tr>
      </tfoot>
    </Table>
  );
};

export default ProductionTotal;
