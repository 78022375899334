import { EProductionGroup } from "../enums/production";

export interface IProductionGroup {
  id: EProductionGroup;
  label: string;
}

export const productionGroups:Array<IProductionGroup> = [
  {id: EProductionGroup.FACTUAL, label: "Factual"},
  {id: EProductionGroup.STUDIO_ENTERTAINMENT, label: "Studio Entertainment"},
  {id: EProductionGroup.SCRIPTED, label: "Scripted"},
  {id: EProductionGroup.RD, label: "R&D"},
  {id: EProductionGroup.OTHERS, label: "Others"}

]

export const getProductionGroup = (id: EProductionGroup):IProductionGroup => {
  return productionGroups.find((item) => item.id === id);
}