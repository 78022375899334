import React, { useEffect } from 'react';
import { IProcountorLedgerReceipt, IAppState } from '../../interfaces';
import { connect } from 'react-redux';

import * as actions from '../../store/actions';
import Scrollable from '../../hoc/Scrollable/Scrollable';
import ContentBox from '../../hoc/ContentBox/ContentBox';
import { Spinner, Table } from 'reactstrap';

interface IStateProps {
  pendingReceipts: IProcountorLedgerReceipt[] | null;
  loading: boolean;
  error: string | null;
}

interface IDispatchProps {
  onFindPendingLedgerReceipts: () => Promise<IProcountorLedgerReceipt[]>;
}

interface IProps extends IStateProps, IDispatchProps {}

const PendingReceipts:React.FC<IProps> = ({ pendingReceipts, loading, error, onFindPendingLedgerReceipts }) => {

  useEffect(() => {
    onFindPendingLedgerReceipts();
  }, [onFindPendingLedgerReceipts]);

  
  return (
    <Scrollable>
      <ContentBox title="Pending Receipts">
        {loading ? (
          <Spinner />
        ) : pendingReceipts == null || pendingReceipts.length === 0 ? (
          <p>No pending receipts</p>
        ) : (
          <Table striped bordered>
            <thead>
              <tr>
                <th>Invoice number</th>
                <th>Name</th>
                <th>Receipt date</th>
                <th>Status</th>
                <th>Type</th>
              </tr>
            </thead>
            <tbody>
              {
                pendingReceipts.map(receipt => (
                  <tr key={receipt.id}>
                    <td>{receipt.invoiceNumber}</td>
                    <td>{receipt.name}</td>
                    <td>{receipt.receiptDate}</td>
                    <td>{receipt.status}</td>
                    <td>{receipt.type}</td>
                  </tr>
                ))}
            </tbody>
          </Table>
        )}
      </ContentBox>
    </Scrollable>
  )
}

const mapStateToProps = (state: IAppState): IStateProps => {
  return {
    pendingReceipts: state.procountor.ledgerReceipts,
    loading: state.procountor.loading,
    error: state.productions.error,
  };
};

const mapDispatchToProps = (dispatch: any): IDispatchProps => {
  return {
    onFindPendingLedgerReceipts: () =>
      dispatch(actions.findPendingLedgerReceipts())
    
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PendingReceipts);