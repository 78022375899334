import React from "react";
import Textarea from "react-textarea-autosize";
import { CustomInput, FormGroup, Input, Label } from "reactstrap";

import CustomSelect from "../CustomSelect/CustomSelect";

const input = ({ formElement, changed }: any) => {
  let inputElement = null;
  switch (formElement.config.elementType) {
    case "input":
      inputElement = (
        <Input
          type={formElement.config.elementConfig.type}
          id={formElement.id}
          placeholder={formElement.config.elementConfig.label}
          value={formElement.config.value}
          onChange={event => changed(event, formElement.id)}
          onFocus={(event: any) => {
            if (formElement.config.elementConfig.selectOnFocus) {
              event.target.select();
            }
          }}
          invalid={!formElement.config.valid && formElement.config.touched}
        />
      );
      break;
    case "textarea":
      let classNames = ["form-control"];
      if (!formElement.config.valid && formElement.config.touched) {
        classNames.push("is-invalid");
      }

      inputElement = (
        <Textarea
          id={formElement.id}
          className={classNames.join(" ")}
          placeholder={formElement.config.elementConfig.label}
          value={formElement.config.value}
          onChange={event => changed(event, formElement.id)}
          minRows={3}
        />
      );
      break;
    case "checkbox":
    case "radio":
      inputElement = formElement.config.elementConfig.options.map(
        (option: any, i: number) => (
          <CustomInput
            key={formElement.id + i}
            type={formElement.config.elementType}
            id={formElement.id + i}
            name={formElement.id}
            label={option.label}
            value={option.id}
            checked={
              formElement.config.value === option.id ||
              (typeof formElement.config.value === "object" &&
                formElement.config.value.indexOf(option.id) !== -1)
            }
            onChange={event => changed(event, formElement.id)}
            invalid={!formElement.config.valid && formElement.config.touched}
          />
        )
      );
      inputElement = <div>{inputElement}</div>;
      break;
    case "customSelect":
      inputElement = (
        <CustomSelect
          value={formElement.config.value}
          onChange={(value: string) =>
            changed({ target: { value } }, formElement.id)
          }
          options={formElement.config.options}
          isMulti={formElement.config.elementConfig.isMulti}
          valueMapper={formElement.config.valueMapper}
          placeholder={
            formElement.config.elementConfig.placeholder ||
            formElement.config.elementConfig.label
          }
        />
      );
      break;
    case "select":
      inputElement = (
        <Input
          type={formElement.config.elementType}
          value={formElement.config.value}
          onChange={event => changed(event, formElement.id)}
          placeholder={
            formElement.config.elementConfig.placeholder ||
            formElement.config.elementConfig.label
          }
        >
          {formElement.config.options.map((item: any) => (
            <option key={item.id} value={item.id}>
              {item.label}
            </option>
          ))}
        </Input>
      );
      break;
    default:
      inputElement = (
        <Input
          type={formElement.config.elementConfig.type}
          id={formElement.id}
          value={formElement.config.value}
          placeholder={formElement.config.elementConfig.label}
          onChange={event => changed(event, formElement.id)}
          invalid={!formElement.config.valid && formElement.config.touched}
        />
      );
  }

  let label = null;
  if (formElement.config.elementConfig.label) {
    label = (
      <Label for={formElement.id}>
        {formElement.config.elementConfig.label}
      </Label>
    );
  }

  let inputEl = (
    <FormGroup>
      {label}
      {inputElement}
    </FormGroup>
  );

  return inputEl;
};

export default input;
