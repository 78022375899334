import React from "react";
import { IProductionDimensionExpenseCSVTalenom } from "../../../../interfaces";

const classes = require('./ProductionDimensionDetailTalenom.module.scss');

interface IProps {
  expense: IProductionDimensionExpenseCSVTalenom;
}

const ProductionDimensionDetailCSVTalenom: React.FC<IProps> = ({ expense }) => {
  return (
    <div className={classes.Grid}>
      <div className={classes.ReceiptDate}>{expense.receiptDate}</div>
      <div className={classes.InvoiceNumber}>{expense.invoiceNumber}</div>
      <div className={classes.Value}>{expense.value} €</div>
      <div className={classes.Description}>
        <i>{expense.notes}</i>
      </div>
    </div>
  );
};

export default ProductionDimensionDetailCSVTalenom;
