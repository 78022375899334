import { IProcountorState } from '../../interfaces';
import { updateObject } from '../../shared/utility';
import { ActionTypes } from '../actions/actionTypes';
import { IProcountorAction } from './../../interfaces/state';

const initialState:IProcountorState = {
    ledgerReceiptsTransfers: null,
    loading: false,
    error: null,
    createSpinner: false,
    createError: null
};

const findLedgerReceiptsTransfersStart = (state:IProcountorState, action:IProcountorAction):IProcountorState => {
    return updateObject(state, { error: null, loading: true});
}

const findLedgerReceiptsTransfersSuccess = (state:IProcountorState, action:IProcountorAction):IProcountorState => {
    return updateObject(state, {
        ledgerReceiptsTransfers: action.ledgerReceiptsTransfers,
        error: null,
        loading: false
    });
}

const findLedgerReceiptsTransfersFail = (state:IProcountorState, action:IProcountorAction):IProcountorState => {
    return updateObject(state, {
        error: action.error,
        loading: false
    });
};

const findPendingLedgerReceiptsStart = (state:IProcountorState, action:IProcountorAction):IProcountorState => {
    return updateObject(state, { error: null, loading: true});
}

const findPendingLedgerReceiptsSuccess = (state:IProcountorState, action:IProcountorAction):IProcountorState => {
    return updateObject(state, {
        ledgerReceipts: action.ledgerReceipts,
        error: null,
        loading: false
    });
}

const findPendingLedgerReceiptsFail = (state:IProcountorState, action:IProcountorAction):IProcountorState => {
    return updateObject(state, {
        error: action.error,
        loading: false
    });
};


const createLedgerReceiptsTransferStart = (state:IProcountorState, action:IProcountorAction):IProcountorState => {
    return updateObject(state, { createError: null, createSpinner: true});
}

const createLedgerReceiptsTransferSuccess = (state:IProcountorState, action:IProcountorAction):IProcountorState => {
    return updateObject(state, {
        createError: null,
        createSpinner: false
    });
}

const createLedgerReceiptsTransferFail = (state:IProcountorState, action:IProcountorAction):IProcountorState => {
    return updateObject(state, {
        createError: action.error,
        createSpinner: false
    });
};

const deleteLedgerReceiptsTransferStart = (state:IProcountorState, action:IProcountorAction):IProcountorState => {
    return updateObject(state, { error: null, loading: true});
}

const deleteLedgerReceiptsTransferSuccess = (state:IProcountorState, action:IProcountorAction):IProcountorState => {
    return updateObject(state, {
        error: null,
        loading: false
    });
}

const deleteLedgerReceiptsTransferFail = (state:IProcountorState, action:IProcountorAction):IProcountorState => {
    return updateObject(state, {
        error: action.error,
        loading: false
    });
};


const reducer = (state:IProcountorState = initialState, action:IProcountorAction):IProcountorState => {
    
    switch (action.type) {
        case ActionTypes.FIND_LEDGER_RECIEPTS_TRANSFERS_START : return findLedgerReceiptsTransfersStart(state, action);
        case ActionTypes.FIND_LEDGER_RECIEPTS_TRANSFERS_SUCCESS : return findLedgerReceiptsTransfersSuccess(state, action);
        case ActionTypes.FIND_LEDGER_RECIEPTS_TRANSFERS_FAIL : return findLedgerReceiptsTransfersFail(state, action);
        case ActionTypes.FIND_PENDING_LEDGER_RECEIPTS_START : return findPendingLedgerReceiptsStart(state, action);
        case ActionTypes.FIND_PENDING_LEDGER_RECEIPTS_SUCCESS : return findPendingLedgerReceiptsSuccess(state, action);
        case ActionTypes.FIND_PENDING_LEDGER_RECEIPTS_FAIL : return findPendingLedgerReceiptsFail(state, action);
        case ActionTypes.CREATE_LEDGER_RECIEPTS_TRANSFER_START : return createLedgerReceiptsTransferStart(state, action);
        case ActionTypes.CREATE_LEDGER_RECIEPTS_TRANSFER_SUCCESS : return createLedgerReceiptsTransferSuccess(state, action);
        case ActionTypes.CREATE_LEDGER_RECIEPTS_TRANSFER_FAIL : return createLedgerReceiptsTransferFail(state, action);
        case ActionTypes.DELETE_LEDGER_RECIEPTS_TRANSFER_START : return deleteLedgerReceiptsTransferStart(state, action);
        case ActionTypes.DELETE_LEDGER_RECIEPTS_TRANSFER_SUCCESS : return deleteLedgerReceiptsTransferSuccess(state, action);
        case ActionTypes.DELETE_LEDGER_RECIEPTS_TRANSFER_FAIL : return deleteLedgerReceiptsTransferFail(state, action);
        default: return state;
    }
}

export default reducer;