import './assets/scss/main.scss';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { applyMiddleware, combineReducers, compose, createStore, Reducer } from 'redux';
import thunk from 'redux-thunk';

import App from './App';
import { IAppState } from './interfaces';
import authReducer from './store/reducers/authReducer';
import usersReducer from './store/reducers/usersReducer';
import productionsReducer from './store/reducers/productionsReducer';
import procountorReducer from './store/reducers/procountorReducer';
import talenomReducer from './store/reducers/talenomReducer';

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: any;
  }
}

const composeEnhancers = process.env.NODE_ENV === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose : compose;

const rootReducer:Reducer<IAppState> = combineReducers({
  auth: authReducer,
  users: usersReducer,
  productions: productionsReducer,
  procountor: procountorReducer,
  talenom: talenomReducer,
});

const store = createStore(rootReducer, 
  composeEnhancers(applyMiddleware(thunk))
)

const root = (
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
)

ReactDOM.render(root, document.getElementById('root'));

