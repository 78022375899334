import React from 'react';

import { IProductionDimensionExpenseCSVSalaries } from '../../../../interfaces';

const classes = require('./ProductionDimensionDetailCSVSalaries.module.scss');

interface IProps {
  expense: IProductionDimensionExpenseCSVSalaries;
}

const ProductionDimensionDetailCSVSalaries: React.FC<IProps> = ({ expense }) => {
  return (
    <div className={classes.Grid}>
      <div className={classes.PersonName}>{expense.personNumber} {expense.personName}</div>
      <div className={classes.Value}>{expense.value} €</div>
      <div className={classes.DateRange}>
        <i>{expense.startDate} - {expense.endDate}</i>
      </div>
    </div>
  );
};

export default ProductionDimensionDetailCSVSalaries;
