import React from 'react';
import Backdrop from '../Backdrop/Backdrop';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const classes = require('./Sidebar.module.scss');

interface IProps {
  show: boolean;
  onClose: () => void;
  children: any;
  title: string;
}

const Sidebar:React.FC<IProps> = (props) => {


  let containerClasses = [classes.Container];

  
  if(props.show) {
    containerClasses.push(classes.Active);
  }


  return (
    <React.Fragment>
      <Backdrop show={props.show} clicked={props.onClose}/> 
      <div className={containerClasses.join(' ')}>
        <div className={classes.Header}>
          <h5>{props.title}</h5>
          <span onClick={props.onClose}>
            <FontAwesomeIcon icon={faTimes} />
          </span>
        </div>
        {props.children}
      </div>
    </React.Fragment>
  );
}


export default Sidebar;