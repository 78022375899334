import React from "react";

const classes = require("./Footer.module.scss");

interface IProps {
  isAuthenticated: boolean
}

const Footer: React.FC<IProps> = props => {
  const year = new Date().getFullYear();

  return props.isAuthenticated ? (
    <footer className={`${classes.Footer} text-muted`}>
      &copy; {year}{" "}
      <a href="https://www.mediawan.fi" target="_blank" className="text-muted" rel="noopener noreferrer">
        Mediawan Finland
      </a>{" "}
      &{" "}
      <a href="https://www.posp.fi" target="_blank" className="text-muted" rel="noopener noreferrer">
        Positive Productions Oy
      </a>
    </footer>
  ) : null;
};

export default Footer;
