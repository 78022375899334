import _ from 'lodash';
import React, { useCallback} from 'react';

import { controlsToFormGroups, validateInput } from '../../shared/utility';



interface IProps {
  controls: any;
  setControls: any;
  onUpdate: (controlName: string, value: string) => void;
}

const AutoUpdate: React.FC<IProps> = (props) => {
  const onUpdate = useCallback(_.debounce((controlName: string, value:string) => {
    props.onUpdate(controlName, value);
  }, 400), []);

  const inputChangedHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
    controlName: string
  ) => {

    const value = event.target.value;
    const validation = validateInput(props.controls, controlName, value);
    props.setControls(validation.controls);
    onUpdate(controlName, value);
  };

  return controlsToFormGroups(props.controls, inputChangedHandler);
}


export default AutoUpdate;