import React, { ReactNode } from "react";
import { Spinner, Button, Alert } from "reactstrap";

const classes = require("./ContentBox.module.scss");


export interface IContentBoxAction {
  text: string;
  clicked: () => void;
}

interface IProps {
  loading?: boolean;
  error?: string;
  children: ReactNode;
  actions?: Array<IContentBoxAction>;
  title?: string;
}

const ContentBox: React.FC<IProps> = ({
  loading,
  actions,
  children,
  title,
  error
}) => {
  if (loading) {
    children = (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Spinner />
      </div>
    );
  }

  if (error) {
    children = (
      <Alert color="danger">{error}</Alert>
    )
  }

  return (
    <React.Fragment>
      <div className={classes.Header}>
        <h3>{title}</h3>
        {actions && (
          <React.Fragment>
            <div className={classes.Actions}>
              {actions.map(action => (
                <Button key={action.text} size="sm" onClick={action.clicked}>
                  {action.text}
                </Button>
              ))}
            </div>
          </React.Fragment>
        )}
      </div>
      {children}
    </React.Fragment>
  );
};

export default ContentBox;
