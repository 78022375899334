import React from "react";
import { Table, Spinner, Button, Badge } from "reactstrap";

import ContentBox from "../../../hoc/ContentBox/ContentBox";
import { ISalaryImportTransfer, ESalaryImportTransferStatus } from "../../../interfaces";

interface IProps {
  salaryImportTransfers: Array<ISalaryImportTransfer>;
  loading: boolean;
  onDelete: (id: string) => void;
  onImport: () => void;
}

const TransfersSalaries: React.FC<IProps> = ({
  salaryImportTransfers,
  loading,
  onDelete,
  onImport
}) => {

  const actions = [{ text: "Import salaries", clicked: onImport }]

  const getStatusColor = (status: ESalaryImportTransferStatus):string =>  {
    switch(status) {
      case ESalaryImportTransferStatus.DONE:
        return "success";
      case ESalaryImportTransferStatus.DELETING:
        return "danger";
      default:
        return "secondary";
    }
  }

  return (
    <ContentBox actions={actions} title="Salaries">
      {loading ? (
        <Spinner />
      ) : salaryImportTransfers == null ? (
        <p>No transfers</p>
      ) : (
        <Table striped bordered>
          <thead>
            <tr>
              <th>Status</th>
              <th>Created</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {salaryImportTransfers != null &&
              salaryImportTransfers.map(transfer => (
                <tr key={transfer.id}>
                  <td><Badge color={getStatusColor(transfer.status)}>{transfer.status}</Badge></td>
                  <td>{transfer.created}</td>
                  <td className="min-width">
                    <Button
                      color="secondary"
                      size="sm"
                      onClick={() => onDelete(transfer.id)}
                    >
                      Delete
                    </Button>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      )}
    </ContentBox>
  );
};

export default TransfersSalaries;
