import { EDimensionUnit } from '../enums/production-dimension';

interface IDimensionUnit {
  id: EDimensionUnit;
  label: string;
}

export const dimensionUnits:Array<IDimensionUnit> = [
  {id: EDimensionUnit.MONTH, label: "Month"},
  {id: EDimensionUnit.WEEK, label: "Week"},
  {id: EDimensionUnit.EPISODE, label: "Episode"},
  {id: EDimensionUnit.DAY, label: "Day"},
  {id: EDimensionUnit.HOUR, label: "Hour"},
  {id: EDimensionUnit.BATCH, label: "Batch"}
];

export const getDimensionUnit = (id: EDimensionUnit):IDimensionUnit => {
  return dimensionUnits.find((item) => item.id === id);
}