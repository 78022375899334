import React from 'react';
import { Table } from 'reactstrap';

import { ISalaryImport } from '../../../interfaces';

interface IProps {
  salaries: Array<ISalaryImport>;
  onSelect: (key: string) => void;
  selected: Array<string>;
}

const ImportSalaries: React.FC<IProps> = ({ salaries, onSelect, selected }) => {
  return (
    <React.Fragment>
      {salaries &&
        salaries.map(salary => {
          if (!salary.items) return null;
          return (
            <div key={salary.id}>
              <h5>
                {salary.personNumber} {salary.personName} {salary.dateRange}
              </h5>
              <Table bordered>
                <thead>
                  <tr>
                    <th />
                    <th>Project</th>
                    <th>Cost pool</th>
                    <th>Price</th>
                  </tr>
                </thead>
                <tbody>
                  {salary.items.map(item => {
                    return (
                      <tr key={item.id}>
                        <td>
                          <input
                            type="checkbox"
                            checked={selected.indexOf(item.id) !== -1}
                            value={item.id}
                            onChange={() => onSelect(item.id)}
                          />
                        </td>
                        <td>{item.projectNumber}</td>
                        <td>{item.costPoolNumber}</td>
                        <td>{item.priceDisplay} €</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          );
        })}
    </React.Fragment>
  );
};

export default ImportSalaries;
