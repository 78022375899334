import React, { useEffect } from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { Table } from "reactstrap";
import { getRole } from '../../data/user-role';
import ContentBox from "../../hoc/ContentBox/ContentBox";
import Scrollable from "../../hoc/Scrollable/Scrollable";
import { IAppState, IUser } from "../../interfaces";
import * as actions from "../../store/actions";


interface IStateProps {
  users: Array<IUser> | null;
  loading: boolean;
  error: string;
}

interface IDispatchProps {
  onFindUsers: () => {};
}

interface IProps extends IStateProps, IDispatchProps, RouteComponentProps {}

const Users: React.FC<IProps> = ({ users, loading, onFindUsers, history, error }) => {
  useEffect(() => {
    onFindUsers();
  }, [onFindUsers]);

  const onEdit = (user: IUser) => {
    history.push(`/users/${user.id}`);
  };

  const onAddUser = () => {
    history.push("/users/add");
  };

  const actions = [{ text: "Add", clicked: onAddUser }];

  return (
    <Scrollable>
      <ContentBox loading={loading} actions={actions} error={error}>
        <Table hover striped bordered>
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Role</th>
            </tr>
          </thead>
          <tbody>
            {users != null &&
              users.map(user => (
                <tr
                  key={user.id}
                  onClick={() => onEdit(user)}
                  style={{cursor: 'pointer'}}
                >
                  <td>{user.name}</td>
                  <td>{user.email}</td>
                  <td>{getRole(user.role).label}</td>
                </tr>
              ))}
          </tbody>
        </Table>
      </ContentBox>
    </Scrollable>
  );
};

const mapStateToProps = (state: IAppState): IStateProps => {
  return {
    users: state.users.users,
    loading: state.users.loading,
    error: state.users.error
  };
};

const mapDispatchToProps = (dispatch: any): IDispatchProps => {
  return {
    onFindUsers: () => dispatch(actions.findUsers())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Users);
