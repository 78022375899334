import React from 'react';

const classes = require('./BoxFooter.module.scss');

interface IProps {
  children: any;
}

const BoxFooter: React.FC<IProps> = ({children}) => {
  return (
    <div className={classes.Container}>
      {children}
    </div>
  )
}


export default BoxFooter;