import { EUserRole } from "../enums/user-role";

interface IUserRole {
  id: EUserRole;
  label: string;
}

export const roles: Array<IUserRole> = [
  { id: EUserRole.ADMIN, label: "Admin" },
  { id: EUserRole.ADMIN_VIEWER, label: "Admin viewer" },
  { id: EUserRole.EXECUTIVE_TEAM, label: "Executive team" },
  { id: EUserRole.PRODUCER, label: "Producers and production managers" },
];

export const getRole = (id: EUserRole): IUserRole => {
  return roles.find(item => item.id === id);
};
