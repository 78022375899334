import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
  Alert
} from "reactstrap";

import { ISalaryImport } from "../../interfaces";
import ImportDropzone from "./ImportDropzone/ImportDropzone";
import ImportSalaries from "./ImportSalaries/ImportSalaries";

interface IProps {
  open: boolean;
  onClose: () => void;
  onUpload: (formData: FormData) => void;
  onImport: (salaries: Array<ISalaryImport>) => void;
  loading: boolean;
  salaries: Array<ISalaryImport>;
  error: string;
}

const ImportDialog: React.FC<IProps> = ({
  open,
  onClose,
  loading,
  onUpload,
  onImport,
  salaries,
  error
}) => {
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    const newSelected: Array<string> = [];
    if (salaries) {
      for (let salary of salaries) {
        if (salary.items) {
          for (let salaryItem of salary.items) {
            newSelected.push(salaryItem.id);
          }
        }
      }
    }
    setSelected(newSelected);
  }, [salaries]);

  const onSelect = (id: string) => {
    const newSelected = [...selected];
    const index = newSelected.indexOf(id);
    index !== -1 ? newSelected.splice(index, 1) : newSelected.push(id);
    setSelected(newSelected);
  };

  const onImportSalaries = () => {
    const importSalaries = salaries
      .map(salary => {
        salary.items = salary.items.filter(
          item => selected.indexOf(item.id) !== -1
        );
        if (salary.items.length === 0) {
          return null;
        }
        return salary;
      })
      .filter(salary => salary !== null);
    onImport(importSalaries);
  };

  const onDrop = (files: Array<File>) => {
    if (files && files.length === 1) {
      const formData = new FormData();
      formData.append("file", files[0]);
      onUpload(formData);
    }
  };

  return (
    <Modal isOpen={open} toggle={loading ? null : onClose} size="lg">
      <ModalHeader toggle={loading ? null : onClose}>
        Import salaries
      </ModalHeader>
      <ModalBody>
        {error ? (
          <Alert color="danger">{error}</Alert>
        ) : loading ? (
          <Spinner />
        ) : salaries ? (
          <ImportSalaries
            salaries={salaries}
            onSelect={onSelect}
            selected={selected}
          />
        ) : (
          <ImportDropzone onDrop={onDrop} />
        )}
      </ModalBody>
      <ModalFooter>
        {salaries && (
          <Button color="primary" onClick={onImportSalaries} disabled={loading}>
            Import selected
          </Button>
        )}
        <Button color="secondary" onClick={onClose} disabled={loading}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ImportDialog;
