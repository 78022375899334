import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Route, RouteComponentProps, Switch } from "react-router";
import { Alert, Button, Nav, NavItem, NavLink } from "reactstrap";

import ProductionBudget from "../../../components/Productions/ProductionBudget/ProductionBudget";
import ProductionDimensionDetail from "../../../components/Productions/ProductionDimensionDetail/ProductionDimensionDetail";
import ProductionDimensionForm from "../../../components/Productions/ProductionDimensionForm/ProductionDimensionForm";
import ProductionForm from "../../../components/Productions/ProductionForm/ProductionForm";
import ProductionTotal from "../../../components/Productions/ProductionTotal/ProductionTotal";
import SingleFieldAutoUpdateContext from "../../../components/SingleFieldAutoUpdate/SingleFieldAutoUpdateContext";
import CustomDialog from "../../../components/UI/CustomDialog/CustomDialog";
import Sidebar from "../../../components/UI/Sidebar/Sidebar";
import ContentBox from "../../../hoc/ContentBox/ContentBox";
import Scrollable from "../../../hoc/Scrollable/Scrollable";
import {
  IAppState,
  IDialog,
  IProduction,
  IProductionDimension,
  IUser,
  IProductionDimensionExpense
} from "../../../interfaces";
import * as actions from "../../../store/actions";
import { hasRoles } from "../../../shared/utility";
import { EUserRole } from "../../../enums/user-role";

const classes = require("./Production.module.scss");

interface IStateProps {
  currentUser: IUser;
  loading: boolean;
  production: IProduction;
  updating: boolean;
  users: Array<IUser>;
  expenses: Array<IProductionDimensionExpense>;
  dimensionSpinner: boolean;
  dimensionError: string;
  expensesSpinner: boolean;
  expensesError: string;
  error: string;
}

interface IDispatchProps {
  onUpdateProductionProperty: (
    id: string,
    propertyName: string,
    value: string
  ) => void;
  onGetProduction: (id: string) => void;
  onFindUsers: () => Array<IUser>;
  onSaveProductionDimension: (
    productionId: string,
    dimension: IProductionDimension
  ) => boolean;
  onUpdateProductionDimension: (
    productionId: string,
    dimension: IProductionDimension
  ) => boolean;
  onUpdateProductionDimensionProperty: (
    productionId: string,
    id: string,
    propertyName: string,
    value: string
  ) => void;
  onGenerateProductionDimensions: (producitonId: string) => void;
  onDeleteProductionDimension: (productionId: string, id: string) => boolean;
  onFindProductionDimensionExpenses: (dimensionId: string) => void;
}

interface IMatch {
  id: string;
  tab: EProductionTab;
}

interface IProps
  extends IStateProps,
    IDispatchProps,
    RouteComponentProps<IMatch> {}

export enum EProductionTab {
  BUDGET = "budget",
  TOTAL = "total"
}

const defaultDialog: IDialog = {
  open: false,
  title: ""
};

const Production: React.FC<IProps> = ({
  currentUser,
  production,
  updating,
  loading,
  error,
  users,
  onGetProduction,
  onUpdateProductionProperty,
  onFindUsers,
  match,
  dimensionSpinner,
  dimensionError,
  expensesSpinner,
  expensesError,
  expenses,
  onSaveProductionDimension,
  onUpdateProductionDimension,
  onUpdateProductionDimensionProperty,
  onGenerateProductionDimensions,
  onDeleteProductionDimension,
  onFindProductionDimensionExpenses,
  history
}) => {
  const [sidebarDimension, setSidebarDimension] = useState(null);
  const [editDimensions, setEditDimensions] = useState(false);
  const [tab, setTab] = useState(match.params.tab);
  const [deleteDimensionDialog, setDeleteDimensionDialog] = useState(
    defaultDialog
  );
  const [sidebarDimensionDetail, setSidebarDimensionDetail] = useState(null);

  useEffect(() => {
    onGetProduction(match.params.id);
    if (hasRoles(currentUser, EUserRole.ADMIN, EUserRole.ADMIN_VIEWER)) {
      onFindUsers();
    }
  }, [onGetProduction, onFindUsers, match.params.id, currentUser]);

  const tabClickHandler = (tab: EProductionTab) => {
    history.push(`/productions/${match.params.id}/${tab}`);
    setTab(tab);
  };

  const onPropertyUpdate = (controlName: string, value: any) => {
    onUpdateProductionProperty(match.params.id, controlName, value);
  };

  const onProductionDimensionSubmit = async (
    dimension: IProductionDimension
  ): Promise<boolean> => {
    let success = false;
    if (dimension.id) {
      success = await onUpdateProductionDimension(match.params.id, dimension);
    } else {
      success = await onSaveProductionDimension(match.params.id, dimension);
    }
    if (success) {
      setSidebarDimension(null);
    }
    return success;
  };

  const onProductionDimensionUpdateProperty = async (
    id: string,
    controlName: string,
    value: string
  ) => {
    onUpdateProductionDimensionProperty(
      match.params.id,
      id,
      controlName,
      value
    );
  };

  const onToggleDimensionEdit = () => {
    setEditDimensions(!editDimensions);
  };

  const onGenerateDimensions = () => {
    onGenerateProductionDimensions(match.params.id);
  };

  const onDeleteDimension = (dimension: IProductionDimension) => {
    setDeleteDimensionDialog({
      onOk: async () => {
        const success = await onDeleteProductionDimension(
          match.params.id,
          dimension.id
        );
        if (success) {
          setDeleteDimensionDialog(defaultDialog);
        }
      },
      open: true,
      title: `Delete dimension`,
      text: (
        <div>
          Are you sure you want to delete dimension{" "}
          <b>
            {dimension.accountNumber} {dimension.name}
          </b>
          ?
        </div>
      )
    });
  };

  const onEditDimension = (dimension: IProductionDimension) => {
    setSidebarDimension(dimension);
  };

  const onShowDimensionDetails = (dimension: IProductionDimension) => {
    onFindProductionDimensionExpenses(dimension.id);
    setSidebarDimensionDetail({
      title: `${dimension.accountNumber} ${dimension.name}`,
      dimension
    });
  };

  if (error) {
    return (
      <Alert className={classes.Alert} color="danger">
        {error}
      </Alert>
    );
  }


  const statusClasses = [classes.StatusText];
  if(production) {
    const { status } = production.totals;
    if(+status.toFixed(2) > 0) {
      statusClasses.push('text-success');
    } else if(+status.toFixed(2) < 0) {
      statusClasses.push('text-danger');
    }
  }

  return (
    <React.Fragment>
      <CustomDialog
        loading={dimensionSpinner}
        onOk={deleteDimensionDialog.onOk}
        onCancel={() => setDeleteDimensionDialog({ open: false })}
        open={deleteDimensionDialog.open}
        title={deleteDimensionDialog.title}
      >
        {deleteDimensionDialog.text}
      </CustomDialog>
      <Sidebar
        title="Add dimension"
        show={sidebarDimension !== null}
        onClose={() => setSidebarDimension(null)}
      >
        <ProductionDimensionForm
          onSubmit={onProductionDimensionSubmit}
          onCloseSidebar={() => setSidebarDimension(null)}
          dimension={sidebarDimension}
          loading={dimensionSpinner}
          error={dimensionError}
        />
      </Sidebar>

      <Sidebar
        title={sidebarDimensionDetail && sidebarDimensionDetail.title}
        show={sidebarDimensionDetail !== null}
        onClose={() => setSidebarDimensionDetail(null)}
      >
        {sidebarDimensionDetail && (
          <ProductionDimensionDetail
            expenseValueTotal={
              sidebarDimensionDetail.dimension.expenseValueTotalDisplay
            }
            expenseExpensesTotal={
              sidebarDimensionDetail.dimension.expenseExpensesTotalDisplay
            }
            loading={expensesSpinner}
            error={expensesError}
            expenses={expenses}
            onCloseSidebar={() => setSidebarDimensionDetail(null)}
          />
        )}
      </Sidebar>

      <div className={classes.Tabs}>
        <Nav tabs>
          <NavItem onClick={() => tabClickHandler(EProductionTab.BUDGET)}>
            <NavLink className={tab === EProductionTab.BUDGET ? "active" : ""}>
              Budget
            </NavLink>
          </NavItem>
          <NavItem onClick={() => tabClickHandler(EProductionTab.TOTAL)}>
            <NavLink className={tab === EProductionTab.TOTAL ? "active" : ""}>
              Summary
            </NavLink>
          </NavItem>
          <NavItem className={classes.ActionText}>
            {production && !updating && `Last edit was ${production.updated}`}
            {updating && "Saving..."}
          </NavItem>
          <NavItem className={classes.BudgetText}>
            {production && production.totals.budget
              ? `Budget ${production.totalBudgetWithExpenses} €`
              : ""}
          </NavItem>
          <NavItem className={statusClasses.join(' ')}>
          {production && production.totals.status
              ? `Status ${production.totals.statusDisplay} €`
              : ""}
          </NavItem>

          
        </Nav>
      </div>
      <Scrollable>
        <ContentBox loading={loading}>
          {production && (
            <ProductionForm
              production={production}
              onPropertyUpdate={onPropertyUpdate}
              users={users}
              currentUser={currentUser}
            />
          )}

          {hasRoles(currentUser, EUserRole.ADMIN) && (
            <Button
              className={classes.Button}
              onClick={() => setSidebarDimension({})}
            >
              Add dimension
            </Button>
          )}
          {production && production.canEdit && (
            <Button
              className={classes.Button}
              onClick={() => onToggleDimensionEdit()}
            >
              Toggle edit
            </Button>
          )}

          {production && !production.dimensions && (
            <Button
              className={classes.Button}
              onClick={() => onGenerateDimensions()}
            >
              Generate dimensions
            </Button>
          )}
          <SingleFieldAutoUpdateContext.Provider
            value={{ onUpdate: onProductionDimensionUpdateProperty }}
          >
            <Switch>
              <Route
                path={`/productions/${match.params.id}/${
                  EProductionTab.BUDGET
                }`}
                render={() => (
                  <ProductionBudget
                    production={production}
                    canEdit={editDimensions}
                    onEditDimension={onEditDimension}
                    onDeleteDimension={onDeleteDimension}
                    currentUser={currentUser}
                  />
                )}
              />
              <Route
                path={`/productions/${match.params.id}/${EProductionTab.TOTAL}`}
                render={() => (
                  <ProductionTotal
                    production={production}
                    canEdit={editDimensions}
                    onShowDimensionDetails={onShowDimensionDetails}
                  />
                )}
              />
            </Switch>
          </SingleFieldAutoUpdateContext.Provider>
        </ContentBox>
      </Scrollable>
    </React.Fragment>
  );
};

const mapStateToProps = (state: IAppState): IStateProps => {
  return {
    currentUser: state.auth.currentUser,
    loading: state.productions.loading,
    error: state.productions.error,
    production: state.productions.production,
    updating: state.productions.updating,
    users: state.users.users,
    dimensionSpinner: state.productions.dimensionSpinner,
    dimensionError: state.productions.dimensionError,
    expenses: state.productions.expenses,
    expensesSpinner: state.productions.expensesSpinner,
    expensesError: state.productions.expensesError
  };
};

const mapDispatchToProps = (dispatch: any): IDispatchProps => {
  return {
    onUpdateProductionProperty: (id, propertyName, value) =>
      dispatch(actions.updateProductionProperty(id, propertyName, value)),
    onGetProduction: id => dispatch(actions.getProduction(id)),
    onFindUsers: () => dispatch(actions.findUsers()),
    onSaveProductionDimension: (productionId, dimension) =>
      dispatch(actions.saveProductionDimension(productionId, dimension)),
    onUpdateProductionDimension: (productionId, dimension) =>
      dispatch(actions.updateProductionDimension(productionId, dimension)),
    onUpdateProductionDimensionProperty: (
      productionId,
      id,
      propertyName,
      value
    ) =>
      dispatch(
        actions.updateProductionDimensionProperty(
          productionId,
          id,
          propertyName,
          value
        )
      ),
    onGenerateProductionDimensions: productionId =>
      dispatch(actions.generateProductionDimensions(productionId)),
    onDeleteProductionDimension: (productionId, id) =>
      dispatch(actions.deleteProductionDimension(productionId, id)),
    onFindProductionDimensionExpenses: dimensionId =>
      dispatch(actions.findProductionDimensionExpenses(dimensionId))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Production);
