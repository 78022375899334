import { IProductionDimension, IProduction, IProductionTotals } from "./../interfaces/domain";
import { EDimensionCategory } from "../enums/production-dimension";

interface IDimensionCategory {
  id: EDimensionCategory;
  label: string;
}

export const dimensionCategories: Array<IDimensionCategory> = [
  /*
  {id: EDimensionCategory.PERSONNEL_EXPENSES, label: "Personnel expenses"},
  */
  { id: EDimensionCategory.SALARIES, label: "Personnel expenses - Salaries" },
  {
    id: EDimensionCategory.SUBCONTRACTING,
    label: "Personnel expenses - Subcontracting"
  },
  { id: EDimensionCategory.ADVANCE_RESEARCH, label: "Advance research" },
  { id: EDimensionCategory.PRODUCTION, label: "Production" },
  { id: EDimensionCategory.OTHER_EXPENSES, label: "Other expenses" },
  { id: EDimensionCategory.POST_PRODUCTION, label: "Post-production" }
];

export const getDimensionCategory = (
  id: EDimensionCategory
): IDimensionCategory => {
  return dimensionCategories.find(item => item.id === id);
};

export interface IDimensionCategoryGroup {
  dimensions: Array<IProductionDimension>;
  title?: string;
  subTitle?: string;
  totals?: IProductionTotals;
  subTotals?: IProductionTotals;
  emptyRow?: boolean;
  totalsTitle?: string;
  subTotalsTitle?: string;

}

export const createCategoriesArray = (production: IProduction): Array<IDimensionCategoryGroup>  => {
  if(!production) return null;

  const dimensions = production.dimensions;

  if(!dimensions) return null;

  const salaries = dimensions.filter(
    dimension => dimension.category === EDimensionCategory.SALARIES
  );
  const subcontracting = dimensions.filter(
    dimension => dimension.category === EDimensionCategory.SUBCONTRACTING
  );
  const productionDimensions = dimensions.filter(
    dimension => dimension.category === EDimensionCategory.PRODUCTION
  );

  const advanceResearch = dimensions.filter(
    dimension => dimension.category === EDimensionCategory.ADVANCE_RESEARCH
  );

  const otherExpenses = dimensions.filter(
    dimension => dimension.category === EDimensionCategory.OTHER_EXPENSES
  );

  const postProductions = dimensions.filter(
    dimension => dimension.category === EDimensionCategory.POST_PRODUCTION
  );

  const categories = [
    {
      dimensions: salaries,
      title: "Personnel expenses",
      subTitle: "Salaries",
      totals: production.salariesWithExpensesTotal,
      totalsTitle: 'Salaries total including social security costs',
      subTotals: production.salariesTotal,
      subTotalsTitle: 'Salaries total excluding social security costs'
    },
    {
      dimensions: subcontracting,
      subTitle: "Subcontracting",
      subTotals: production.subcontractingTotal,
      subTotalsTitle: 'Subcontracting total',
      totals: production.personnelExpensesTotal,
      totalsTitle: 'Personnel expenses total',
      emptyRow: true
    },
    {
      dimensions: advanceResearch,
      title: "Pre-production",
      totals: production.advanceResearchTotal,
      emptyRow: true
    },
    {
      dimensions: productionDimensions,
      title: "Production",
      totals: production.productionTotal,
      emptyRow: true
    },
    {
      dimensions: otherExpenses,
      title: "Other expenses",
      totals: production.otherExpensesTotal,
      emptyRow: true
    },
    {
      dimensions: postProductions,
      title: "Post-production",
      totals: production.postProductionsTotal,
      emptyRow: true
    }
  ];

  return categories;
};
