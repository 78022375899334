import { IAppState } from '../../interfaces/state';
import axios from '../../axios';
import { ActionTypes } from './actionTypes';

import { IAuthAction, IUser } from '../../interfaces';
import { Dispatch } from 'redux';
import { AxiosError } from 'axios';

export const storeAuthRedirect = (redirect:string):IAuthAction => {
    return {
        type: ActionTypes.STORE_AUTH_REDIRECT,
        redirect
    };
};

export const logoutStart = ():IAuthAction => {
    return {
        type: ActionTypes.LOGOUT_START
    };
};

export const logout = () => {
    return async (dispatch:any, getState:any) => {
        const state:IAppState = getState();
        dispatch(logoutStart());
        const { logoutUrl } = state.auth.currentUser;
        window.location.href = logoutUrl;
    };
};


export const clearError = ():IAuthAction => {
    return {
        type: ActionTypes.CLEAR_ERROR
    };
};


export const getCurrentUserStart = ():IAuthAction => {
    return {
        type: ActionTypes.GET_CURRENT_USER_START
    };
};

export const getCurrentUserSuccess = (currentUser:IUser):IAuthAction => {
    return {
        type: ActionTypes.GET_CURRENT_USER_SUCCESS,
        currentUser
    };
};

export const getCurrentUserFail = ():IAuthAction => {
    return {
        type: ActionTypes.GET_CURRENT_USER_FAIL
    }
};

export const getCurrentUser = () => {
    return async (dispatch:Dispatch) => {
        try {
            dispatch(getCurrentUserStart())
            const res = await axios.get('/json/users/currentuser');
            const user = res.data;
            if (user && user.id)  {
                dispatch(getCurrentUserSuccess(user));
            } else {
                dispatch(getCurrentUserFail())
            }
        } catch (err) {
            if (err) {
                const error = err as AxiosError<any>;
                const resp = error.response;
                if (resp) {
                    const { status, data } = resp;
                    if (status === 302) {
                        dispatch(storeAuthRedirect(data))
                    }
                    if(data.indexOf("http") === 0) {
                        return;
                    }
                }
            }
            dispatch(getCurrentUserFail());
        }
        
    };
};