// import productions from '../../data/productions.json';
import { IProductionAction, IProductionState } from '../../interfaces';
import { updateObject } from '../../shared/utility';
import { ActionTypes } from '../actions/actionTypes';

const initialState:IProductionState = {
    productions: null,
    loading: false,
    error: null,
    production: null,
    success: false,
    search: null,
    updating: false,
    dimensionSpinner: false,
    dimensionError: null,
    importSpinner: false,
    importError: null,
    expensesSpinner: false,
    expensesError: null,
    expenses: null,
    salaries: null
};

const clearProductionsState = (state:IProductionState, action:IProductionAction):IProductionState => {
    return initialState;
}

const findProductionsStart = (state:IProductionState, action:IProductionAction):IProductionState => {
    return updateObject(state, { error: null, loading: true});
}

const findProductionsSuccess = (state:IProductionState, action:IProductionAction):IProductionState => {
    return updateObject(state, {
        productions: action.productions,
        error: null,
        loading: false,
        production: null
    });
}

const findProductionsFail = (state:IProductionState, action:IProductionAction):IProductionState => {
    return updateObject(state, {
        error: action.error,
        loading: false
    });
};

const searchProductionsFail = (state:IProductionState, action:IProductionAction):IProductionState => {
    return updateObject(state, {
        error: action.error,
        loading: false
    });
};

const searchProductionsStart = (state:IProductionState, action:IProductionAction):IProductionState => {
    return updateObject(state, { error: null, loading: true});
}

const searchProductionsSuccess = (state:IProductionState, action:IProductionAction):IProductionState => {
    return updateObject(state, {
        search: action.search,
        error: null,
        loading: false
    });
}

const getProductionStart = (state:IProductionState, action:IProductionAction):IProductionState => {
    return updateObject(state, { error: null, loading: true});
}

const getProductionSuccess = (state:IProductionState, action:IProductionAction):IProductionState => {
    return updateObject(state, {
        production: action.production,
        error: null,
        loading: false
    });
}

const getProductionFail = (state:IProductionState, action:IProductionAction):IProductionState => {
    return updateObject(state, {
        error: action.error,
        loading: false
    });
};


const copyProductionStart = (state:IProductionState, action:IProductionAction):IProductionState => {
    return updateObject(state, { error: null, loading: true});
}

const copyProductionSuccess = (state:IProductionState, action:IProductionAction):IProductionState => {
    const newProductions = [...state.productions];
    newProductions.push(action.production);

    return updateObject(state, {
        productions: newProductions,
        error: null,
        loading: false
    });
}

const copyProductionFail = (state:IProductionState, action:IProductionAction):IProductionState => {
    return updateObject(state, {
        error: action.error,
        loading: false
    });
};

const deleteProductionStart = (state:IProductionState, action:IProductionAction):IProductionState => {
    return updateObject(state, { error: null, loading: true});
}

const deleteProductionSuccess = (state:IProductionState, action:IProductionAction):IProductionState => {
    return updateObject(state, {
        error: null,
        loading: false
    });
}

const deleteProductionFail = (state:IProductionState, action:IProductionAction):IProductionState => {
    return updateObject(state, {
        error: action.error,
        loading: false
    });
};

const createProductionStart = (state:IProductionState, action:IProductionAction):IProductionState => {
    return updateObject(state, { error: null, loading: true});
}

const createProductionSuccess = (state:IProductionState, action:IProductionAction):IProductionState => {
    return updateObject(state, {
        error: null,
        success: true,
        loading: false
    });
}

const createProductionFail = (state:IProductionState, action:IProductionAction):IProductionState => {
    return updateObject(state, {
        error: action.error,
        loading: false
    });
};

const updateProductionPropertyStart = (state:IProductionState, action:IProductionAction):IProductionState => {
    return updateObject(state, { error: null, updating: true});
}

const updateProductionPropertySuccess = (state:IProductionState, action:IProductionAction):IProductionState => {
    return updateObject(state, {
        error: null,
        success: true,
        updating: false,
        production: action.production
    });
}

const updateProductionPropertyFail = (state:IProductionState, action:IProductionAction):IProductionState => {
    return updateObject(state, {
        error: action.error,
        updating: false
    });
};

const saveProductionDimensionStart = (state:IProductionState, action:IProductionAction):IProductionState => {
    return updateObject(state, { dimensionError: null, dimensionSpinner: true});
}

const saveProductionDimensionSuccess = (state:IProductionState, action:IProductionAction):IProductionState => {
    return updateObject(state, {
        dimensionError: null,
        dimensionSpinner: false,
        production: action.production
    });
}

const saveProductionDimensionFail = (state:IProductionState, action:IProductionAction):IProductionState => {
    return updateObject(state, {
        dimensionError: action.error,
        dimensionSpinner: false
    });
};

const updateProductionDimensionStart = (state:IProductionState, action:IProductionAction):IProductionState => {
    return updateObject(state, { dimensionError: null, dimensionSpinner: true});
}

const updateProductionDimensionSuccess = (state:IProductionState, action:IProductionAction):IProductionState => {
    return updateObject(state, {
        dimensionError: null,
        dimensionSpinner: false,
        production: action.production
    });
}

const updateProductionDimensionFail = (state:IProductionState, action:IProductionAction):IProductionState => {
    return updateObject(state, {
        dimensionError: action.error,
        dimensionSpinner: false
    });
};

const updateProductionDimensionPropertyStart = (state:IProductionState, action:IProductionAction):IProductionState => {
    return updateObject(state, { error: null, updating: true});
}

const updateProductionDimensionPropertySuccess = (state:IProductionState, action:IProductionAction):IProductionState => {
    return updateObject(state, {
        error: null,
        success: true,
        updating: false,
        production: action.production
    });
}

const updateProductionDimensionPropertyFail = (state:IProductionState, action:IProductionAction):IProductionState => {
    return updateObject(state, {
        error: action.error,
        updating: false
    });
};

const deleteProductionDimensionStart = (state:IProductionState, action:IProductionAction):IProductionState => {
    return updateObject(state, { dimensionError: null, dimensionSpinner: true});
}

const deleteProductionDimensionSuccess = (state:IProductionState, action:IProductionAction):IProductionState => {
    return updateObject(state, {
        dimensionError: null,
        dimensionSpinner: false,
        production: action.production
    });
}

const deleteProductionDimensionFail = (state:IProductionState, action:IProductionAction):IProductionState => {
    return updateObject(state, {
        dimensionError: action.error,
        dimensionSpinner: false
    });
};

const generateProductionDimensionsStart = (state:IProductionState, action:IProductionAction):IProductionState => {
    return updateObject(state, { dimensionError: null, loading: true});
}

const generateProductionDimensionsSuccess = (state:IProductionState, action:IProductionAction):IProductionState => {
    return updateObject(state, {
        loading: false,
        production: action.production
    });
}

const generateProductionDimensionsFail = (state:IProductionState, action:IProductionAction):IProductionState => {
    return updateObject(state, {
        error: action.error,
        loading: false
    });
};

const findProductionDimensionExpensesStart = (state:IProductionState, action:IProductionAction):IProductionState => {
    return updateObject(state, { expenses: null, expensesError: null, expensesSpinner: true});
}

const findProductionDimensionExpensesSuccess = (state:IProductionState, action:IProductionAction):IProductionState => {
    return updateObject(state, {
        expenses: action.expenses,
        expensesError: null,
        expensesSpinner: false
    });
}

const findProductionDimensionExpensesFail = (state:IProductionState, action:IProductionAction):IProductionState => {
    return updateObject(state, {
        expensesError: action.error,
        expensesSpinner: false
    });
};

const uploadProductionSalariesStart = (state:IProductionState, action:IProductionAction):IProductionState => {
    return updateObject(state, { importError: null, importSpinner: true});
}

const uploadProductionSalariesSuccess = (state:IProductionState, action:IProductionAction):IProductionState => {
    return updateObject(state, {
        importSpinner: false,
        salaries: action.salaries
    });
}

const uploadProductionSalariesFail = (state:IProductionState, action:IProductionAction):IProductionState => {
    return updateObject(state, {
        importError: action.error,
        importSpinner: false
    });
};

const importProductionSalariesStart = (state:IProductionState, action:IProductionAction):IProductionState => {
    return updateObject(state, { importError: null, importSpinner: true});
}

const importProductionSalariesSuccess = (state:IProductionState, action:IProductionAction):IProductionState => {
    return updateObject(state, {
        importSpinner: false,
        importError: null,
        salaries: null
    });
}

const importProductionSalariesFail = (state:IProductionState, action:IProductionAction):IProductionState => {
    return updateObject(state, {
        importError: action.error,
        importSpinner: false,
        salaries: null
    });
};

const findProductionSalaryImportTransfersStart = (state:IProductionState, action:IProductionAction):IProductionState => {
    return updateObject(state, { error: null, loading: true});
}

const findProductionSalaryImportTransfersSuccess = (state:IProductionState, action:IProductionAction):IProductionState => {
    return updateObject(state, {
        salaryTransfers: action.salaryTransfers,
        error: null,
        loading: false,
    });
}

const findProductionSalaryImportTransfersFail = (state:IProductionState, action:IProductionAction):IProductionState => {
    return updateObject(state, {
        error: action.error,
        loading: false
    });
};

const deleteProductionSalaryImportTransferStart = (state:IProductionState, action:IProductionAction):IProductionState => {
    return updateObject(state, { error: null, loading: true});
}

const deleteProductionSalaryImportTransferSuccess = (state:IProductionState, action:IProductionAction):IProductionState => {
    return updateObject(state, {
        error: null,
        loading: false,
    });
}

const deleteProductionSalaryImportTransferFail = (state:IProductionState, action:IProductionAction):IProductionState => {
    return updateObject(state, {
        error: action.error,
        loading: false
    });
};

const clearProductionSalariesState = (state:IProductionState, action:IProductionAction):IProductionState => {
    return updateObject(state, {
        salaries: null,
        importError: null,
        importSpinner: false
    });
}

const reducer = (state:IProductionState = initialState, action:IProductionAction):IProductionState => {
    
    switch (action.type) {
        case ActionTypes.CLEAR_PRODUCTIONS_STATE : return clearProductionsState(state, action);
        case ActionTypes.FIND_PRODUCTIONS_START : return findProductionsStart(state, action);
        case ActionTypes.FIND_PRODUCTIONS_SUCCESS : return findProductionsSuccess(state, action);
        case ActionTypes.FIND_PRODUCTIONS_FAIL : return findProductionsFail(state, action);
        case ActionTypes.SEARCH_PRODUCTIONS_START : return searchProductionsStart(state, action);
        case ActionTypes.SEARCH_PRODUCTIONS_SUCCESS : return searchProductionsSuccess(state, action);
        case ActionTypes.SEARCH_PRODUCTIONS_FAIL : return searchProductionsFail(state, action);
        case ActionTypes.GET_PRODUCTION_START : return getProductionStart(state, action);
        case ActionTypes.GET_PRODUCTION_SUCCESS : return getProductionSuccess(state, action);
        case ActionTypes.GET_PRODUCTION_FAIL : return getProductionFail(state, action);
        case ActionTypes.COPY_PRODUCTION_START : return copyProductionStart(state, action);
        case ActionTypes.COPY_PRODUCTION_SUCCESS : return copyProductionSuccess(state, action);
        case ActionTypes.COPY_PRODUCTION_FAIL : return copyProductionFail(state, action);
        case ActionTypes.DELETE_PRODUCTION_START : return deleteProductionStart(state, action);
        case ActionTypes.DELETE_PRODUCTION_SUCCESS : return deleteProductionSuccess(state, action);
        case ActionTypes.DELETE_PRODUCTION_FAIL : return deleteProductionFail(state, action);
        case ActionTypes.CREATE_PRODUCTION_START : return createProductionStart(state, action);
        case ActionTypes.CREATE_PRODUCTION_SUCCESS : return createProductionSuccess(state, action);
        case ActionTypes.CREATE_PRODUCTION_FAIL : return createProductionFail(state, action);
        case ActionTypes.UPDATE_PRODUCTION_PROPERTY_START : return updateProductionPropertyStart(state, action);
        case ActionTypes.UPDATE_PRODUCTION_PROPERTY_SUCCESS : return updateProductionPropertySuccess(state, action);
        case ActionTypes.UPDATE_PRODUCTION_PROPERTY_FAIL : return updateProductionPropertyFail(state, action);
        case ActionTypes.SAVE_PRODUCTION_DIMENSION_START : return saveProductionDimensionStart(state, action);
        case ActionTypes.SAVE_PRODUCTION_DIMENSION_SUCCESS : return saveProductionDimensionSuccess(state, action);
        case ActionTypes.SAVE_PRODUCTION_DIMENSION_FAIL : return saveProductionDimensionFail(state, action);
        case ActionTypes.UPDATE_PRODUCTION_DIMENSION_START : return updateProductionDimensionStart(state, action);
        case ActionTypes.UPDATE_PRODUCTION_DIMENSION_SUCCESS : return updateProductionDimensionSuccess(state, action);
        case ActionTypes.UPDATE_PRODUCTION_DIMENSION_FAIL : return updateProductionDimensionFail(state, action);
        case ActionTypes.UPDATE_PRODUCTION_DIMENSION_PROPERTY_START : return updateProductionDimensionPropertyStart(state, action);
        case ActionTypes.UPDATE_PRODUCTION_DIMENSION_PROPERTY_SUCCESS : return updateProductionDimensionPropertySuccess(state, action);
        case ActionTypes.UPDATE_PRODUCTION_DIMENSION_PROPERTY_FAIL : return updateProductionDimensionPropertyFail(state, action);
        case ActionTypes.GENERATE_PRODUCTION_DIMENSIONS_START : return generateProductionDimensionsStart(state, action);
        case ActionTypes.GENERATE_PRODUCTION_DIMENSIONS_SUCCESS : return generateProductionDimensionsSuccess(state, action);
        case ActionTypes.GENERATE_PRODUCTION_DIMENSIONS_FAIL : return generateProductionDimensionsFail(state, action);
        case ActionTypes.FIND_PRODUCTION_DIMENSION_EXPENSES_START : return findProductionDimensionExpensesStart(state, action);
        case ActionTypes.FIND_PRODUCTION_DIMENSION_EXPENSES_SUCCESS : return findProductionDimensionExpensesSuccess(state, action);
        case ActionTypes.FIND_PRODUCTION_DIMENSION_EXPENSES_FAIL : return findProductionDimensionExpensesFail(state, action);
        case ActionTypes.DELETE_PRODUCTION_DIMENSION_START : return deleteProductionDimensionStart(state, action);
        case ActionTypes.DELETE_PRODUCTION_DIMENSION_SUCCESS : return deleteProductionDimensionSuccess(state, action);
        case ActionTypes.DELETE_PRODUCTION_DIMENSION_FAIL : return deleteProductionDimensionFail(state, action);
        case ActionTypes.UPLOAD_PRODUCTION_SALARIES_START : return uploadProductionSalariesStart(state, action);
        case ActionTypes.UPLOAD_PRODUCTION_SALARIES_SUCCESS : return uploadProductionSalariesSuccess(state, action);
        case ActionTypes.UPLOAD_PRODUCTION_SALARIES_FAIL : return uploadProductionSalariesFail(state, action);
        case ActionTypes.IMPORT_PRODUCTION_SALARIES_START : return importProductionSalariesStart(state, action);
        case ActionTypes.IMPORT_PRODUCTION_SALARIES_SUCCESS : return importProductionSalariesSuccess(state, action);
        case ActionTypes.IMPORT_PRODUCTION_SALARIES_FAIL : return importProductionSalariesFail(state, action);
        case ActionTypes.FIND_PRODUCTION_SALARY_IMPORT_TRANSFERS_START : return findProductionSalaryImportTransfersStart(state, action);
        case ActionTypes.FIND_PRODUCTION_SALARY_IMPORT_TRANSFERS_SUCCESS : return findProductionSalaryImportTransfersSuccess(state, action);
        case ActionTypes.FIND_PRODUCTION_SALARY_IMPORT_TRANSFERS_FAIL : return findProductionSalaryImportTransfersFail(state, action);
        case ActionTypes.DELETE_PRODUCTION_SALARY_IMPORT_TRANSFER_START : return deleteProductionSalaryImportTransferStart(state, action);
        case ActionTypes.DELETE_PRODUCTION_SALARY_IMPORT_TRANSFER_SUCCESS : return deleteProductionSalaryImportTransferSuccess(state, action);
        case ActionTypes.DELETE_PRODUCTION_SALARY_IMPORT_TRANSFER_FAIL : return deleteProductionSalaryImportTransferFail(state, action);
        case ActionTypes.CLEAR_PRODUCTION_SALARIES_STATE : return clearProductionSalariesState(state, action);
        default: return state;

    }
}

export default reducer;