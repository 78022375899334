import React, { useState, useEffect } from 'react';
import { Button, Spinner, Alert } from 'reactstrap';

import { dimensionCategories } from '../../../data/dimension-category';
import { dimensionUnits } from '../../../data/dimension-unit';
import { IProductionDimension } from '../../../interfaces';
import { controlsToFormGroups, getFormData, validateInput, initForm } from '../../../shared/utility';
import BoxBody from '../../UI/BoxBody/BoxBody';
import BoxFooter from '../../UI/BoxFooter/BoxFooter';

interface IProps {
  onSubmit: (data: any) => Promise<boolean>;
  onCloseSidebar: () => void;
  loading: boolean;
  error: string;
  dimension: IProductionDimension;
}

const initialControls = {
  name: {
    elementType: "input",
    elementConfig: {
      label: "Name",
      type: "text"
    },
    value: "",
    valid: true
  },
  accountNumber: {
    elementType: "input",
    elementConfig: {
      label: "Account number",
      type: "number"
    },
    value: "",
    valid: true
  },
  unit: {
    elementType: "customSelect",
    elementConfig: {
      label: "Unit"
    },
    valid: false,
    touched: false,
    value: "",
    options: dimensionUnits
  },
  amount: {
    elementType: "input",
    elementConfig: {
      label: "Amount",
      type: "number"
    },
    value: "",
    valid: true
  },
  price: {
    elementType: "input",
    elementConfig: {
      label: "Price ",
      type: "number"
    },
    value: "",
    valid: true
  },
  category: {
    elementType: "customSelect",
    elementConfig: {
      label: "Category"
    },
    valid: false,
    touched: false,
    value: "",
    options: dimensionCategories
  }
}

const ProductionDimensionForm: React.FC<IProps> = props => {
  const [controls, setControls] = useState(initialControls);

  useEffect(() => {
    const data = initForm(initialControls, props.dimension);
    setControls(data.controls);
  }, [props.dimension])

  const inputChangedHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
    controlName: string
  ) => {
    const value = event.target.value;
    const validation = validateInput(controls, controlName, value);
    setControls(validation.controls);
  };

  const onSubmit = async () => {
    const dimension = getFormData(controls) as IProductionDimension;
    if(props.dimension.id) {
      dimension.id = props.dimension.id;
    }
    const success = await props.onSubmit(dimension);
    if(success) {
      setControls(initialControls);
    }
  }

  return (
    <React.Fragment>
      <BoxBody>
        {props.loading ? <Spinner /> : controlsToFormGroups(controls, inputChangedHandler)}

        {props.error && <Alert color="danger">{props.error}</Alert>}
      </BoxBody>
      <BoxFooter>
        <Button color="primary" onClick={onSubmit} disabled={props.loading}>Submit</Button>
        <Button onClick={props.onCloseSidebar} disabled={props.loading}>Close</Button>
      </BoxFooter>
    </React.Fragment>
  );
};

export default ProductionDimensionForm;
