import { ActionTypes } from '../actions/actionTypes';
import { ITalenomAction, ITalenomState } from './../../interfaces/state';

type ReducerState = ITalenomState;
type ReducerAction = ITalenomAction;
type ReducerSignature = (
  state: ReducerState,
  action: ReducerAction
) => ReducerState;

const initialState: ReducerState = {
  talenomTransfers: null,
  loading: false,
  error: null,
};

const findTransfersStart: ReducerSignature = (state, action): ReducerState => {
  return {
    ...state,
    error: null,
    loading: true,
  };
};

const findTransfersSuccess: ReducerSignature = (
  state,
  action
): ReducerState => {
  return {
    ...state,
    talenomTransfers: action.talenomTransfers,
    error: null,
    loading: false,
  };
};

const findTransfersFail: ReducerSignature = (state, action): ReducerState => {
  return {
    ...state,
    error: action.error,
    loading: false,
  };
};

const deleteTransferStart: ReducerSignature = (state, action): ReducerState => {
  return { ...state, error: null, loading: true };
};

const deleteTransferSuccess: ReducerSignature = (
  state,
  action
): ReducerState => {
  return {
    ...state,
    error: null,
    loading: false,
  };
};

const deleteTransferFail: ReducerSignature = (state, action): ReducerState => {
  return {
    ...state,
    error: action.error,
    loading: false,
  };
};

const reducer: ReducerSignature = (
  state = initialState,
  action
): ReducerState => {
  switch (action.type) {
    case ActionTypes.TALENOM_FIND_TRANSFERS_START:
      return findTransfersStart(state, action);
    case ActionTypes.TALENOM_FIND_TRANSFERS_SUCCESS:
      return findTransfersSuccess(state, action);
    case ActionTypes.TALENOM_FIND_TRANSFERS_FAIL:
      return findTransfersFail(state, action);
    case ActionTypes.TALENOM_DELETE_TRANSFER_START:
      return deleteTransferStart(state, action);
    case ActionTypes.TALENOM_DELETE_TRANSFER_SUCCESS:
      return deleteTransferSuccess(state, action);
    case ActionTypes.TALENOM_DELETE_TRANSFER_FAIL:
      return deleteTransferFail(state, action);
    default:
      return state;
  }
};

export default reducer;
