import React from 'react';
import { connect } from 'react-redux';

import Navigation from '../../components/Navigation/Navigation';
import { IAppState, IUser } from '../../interfaces';

const classes = require("./Layout.module.css");

interface IStateProps {
  isAuthenticated: boolean;
  currentUser: IUser;
}

interface IProps extends IStateProps {
  children: any;
}

const Layout: React.FC<IProps> = props => {
  return (
    <div className={classes.root}>
      <Navigation
        currentUser={props.currentUser}
      />
      {props.children}
    </div>
  );
};

const mapStateToProps = (state: IAppState): IStateProps => {
  return {
    isAuthenticated: state.auth.currentUser !== null,
    currentUser: state.auth.currentUser
  };
};

export default connect(mapStateToProps)(Layout);
