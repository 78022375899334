import React, { useEffect, useState } from "react";
import { Form } from "reactstrap";
import { getProductionGroup, productionGroups } from "../../../data/production-group";
import { EProductionGroup } from "../../../enums/production";
import { EUserRole } from "../../../enums/user-role";
import { IProduction, IUser } from "../../../interfaces";
import { hasRoles, initForm } from "../../../shared/utility";
import AutoUpdate from "../../AutoUpdate/AutoUpdate";


const classes = require("./ProductionForm.module.scss");

interface IProps {
  production: IProduction;
  onPropertyUpdate: (controlName: string, value: string) => void;
  users: Array<IUser>;
  currentUser: IUser;
}

const ProductionForm: React.FC<IProps> = ({
  production,
  onPropertyUpdate,
  users,
  currentUser
}) => {
  const [controls, setControls] = useState({
    nameEn: {
      elementType: "input",
      elementConfig: {
        label: "Production name",
        type: "text",
        inline: true
      },
      value: "",
      valid: true,
      roles: {
        editProperty: "canEdit",
        edit: true,
        show: true
      }
    },
    nameFi: {
      elementType: "input",
      elementConfig: {
        label: "Production name (fi)",
        type: "text",
        inline: true
      },
      value: "",
      valid: true,
      roles: {
        editProperty: "canEdit",
        edit: true,
        show: true
      }
    },
    projectNumber: {
      elementType: "input",
      elementConfig: {
        label: "Project number",
        type: "text",
        inline: true
      },
      value: "",
      valid: true,
      roles: {
        editProperty: "canEdit",
        edit: true,
        show: true
      }
    },
    numberOfEpisodes: {
      elementType: "input",
      elementConfig: {
        label: "Episodes",
        type: "number",
        inline: true
      },
      value: "",
      valid: true,
      roles: {
        editProperty: "canEdit",
        edit: true,
        show: true
      }
    },
    durationInMinutes: {
      elementType: "input",
      elementConfig: {
        label: "Duration (min)",
        type: "number",
        inline: true
      },
      value: "",
      valid: true,
      roles: {
        editProperty: "canEdit",
        edit: true,
        show: true
      }
    },
    salesPrice: {
      elementType: "input",
      elementConfig: {
        label: "Sales price",
        type: "number",
        inline: true
      },
      value: "",
      valueShowProperty: "salesPriceDisplay",
      valid: true,
      roles: {
        editProperty: "canEdit",
        edit: hasRoles(currentUser, EUserRole.ADMIN, EUserRole.ADMIN_VIEWER, EUserRole.EXECUTIVE_TEAM),
        show: hasRoles(currentUser, EUserRole.ADMIN_VIEWER)
      }
    },
    group: {
      elementType: "customSelect",
      elementConfig: {
        label: "Group"
      },
      valid: false,
      touched: false,
      value: "",
      options: productionGroups,
      roles: {
        editProperty: "canEdit",
        edit: true,
        show: true
      },
      showMapper: (id: EProductionGroup) => {
        return (id && getProductionGroup(id).label) || "";
      }
    },
    notes: {
      elementType: "textarea",
      elementConfig: {
        label: 'Notes',
        type: "text"
      },
      value: "",
      valid: true
    }
  });

  const [producerControl, setProducerControl] = useState({
    producers: {
      elementType: "customSelect",
      elementConfig: {
        label: "Producers",
        isMulti: true
      },
      valid: true,
      value: "",
      options: [],
      roles: {
        editProperty: "canEdit",
        edit: hasRoles(currentUser, EUserRole.ADMIN, EUserRole.ADMIN_VIEWER),
        show: true
      },
      showMapper: (users: Array<IUser>) => {
        return (users && users.map(user => user.name).join(", ")) || "";
      }
    }
  });

  useEffect(() => {
    const data = initForm(producerControl, production);
    if (users) {
      data.controls.producers.options = users
        .filter(user =>
          hasRoles(
            user,
            EUserRole.ADMIN_VIEWER,
            EUserRole.EXECUTIVE_TEAM,
            EUserRole.PRODUCER
          )
        )
        .map(user => ({ id: user.id, label: user.name }));
      data.controls.producers.valueMapper = (ids: Array<string>) => {
        return ids.map(id => users.find(user => user.id === id));
      };
    }
    setProducerControl(data.controls);
    // eslint-disable-next-line
  }, [users]);

  useEffect(() => {
    if (production) {
      const data = initForm(controls, production);
      setControls(data.controls);
    }

    // eslint-disable-next-line
  }, []);

  return (
    <Form className={classes.Form}>
      <AutoUpdate
        controls={controls}
        setControls={setControls}
        onUpdate={onPropertyUpdate}
      />
      <AutoUpdate
        controls={producerControl}
        setControls={setProducerControl}
        onUpdate={onPropertyUpdate}
      />
    </Form>
  );
};

export default ProductionForm;
