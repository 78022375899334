export enum EDimensionCategory {
  PERSONNEL_EXPENSES = 'PERSONNEL_EXPENSES', 
  PRODUCTION = 'PRODUCTION', 
  ADVANCE_RESEARCH = 'ADVANCE_RESEARCH', 
  OTHER_EXPENSES = 'OTHER_EXPENSES', 
  SALARIES = 'SALARIES',
  SUBCONTRACTING = 'SUBCONTRACTING',
  POST_PRODUCTION = 'POST_PRODUCTION'
}

export enum EDimensionUnit {
  MONTH = 'MONTH',
  WEEK = 'WEEK',
  DAY = 'DAY',
  HOUR = 'HOUR',
  EPISODE = 'EPISODE',
  BATCH = 'BATCH'
}