export enum ActionTypes {
  STORE_AUTH_REDIRECT,
  LOGOUT_START,
  CLEAR_ERROR,
  GET_CURRENT_USER_START,
  GET_CURRENT_USER_SUCCESS,
  GET_CURRENT_USER_FAIL,

  FIND_USERS_START,
  FIND_USERS_SUCCESS,
  FIND_USERS_FAIL,
  SEARCH_USERS_START,
  SEARCH_USERS_SUCCESS,
  SEARCH_USERS_FAIL,
  GET_USER_START,
  GET_USER_SUCCESS,
  GET_USER_FAIL,
  UPDATE_USER_START,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
  SAVE_USER_START,
  SAVE_USER_SUCCESS,
  SAVE_USER_FAIL,

  CLEAR_PRODUCTIONS_STATE,
  FIND_PRODUCTIONS_START,
  FIND_PRODUCTIONS_SUCCESS,
  FIND_PRODUCTIONS_FAIL,
  SEARCH_PRODUCTIONS_START,
  SEARCH_PRODUCTIONS_SUCCESS,
  SEARCH_PRODUCTIONS_FAIL,
  GET_PRODUCTION_START,
  GET_PRODUCTION_SUCCESS,
  GET_PRODUCTION_FAIL,
  COPY_PRODUCTION_START,
  COPY_PRODUCTION_SUCCESS,
  COPY_PRODUCTION_FAIL,
  DELETE_PRODUCTION_START,
  DELETE_PRODUCTION_SUCCESS,
  DELETE_PRODUCTION_FAIL,
  UPDATE_PRODUCTION_PROPERTY_START,
  UPDATE_PRODUCTION_PROPERTY_SUCCESS,
  UPDATE_PRODUCTION_PROPERTY_FAIL,
  CREATE_PRODUCTION_START,
  CREATE_PRODUCTION_SUCCESS,
  CREATE_PRODUCTION_FAIL,
  SAVE_PRODUCTION_DIMENSION_START,
  SAVE_PRODUCTION_DIMENSION_SUCCESS,
  SAVE_PRODUCTION_DIMENSION_FAIL,
  UPDATE_PRODUCTION_DIMENSION_START,
  UPDATE_PRODUCTION_DIMENSION_SUCCESS,
  UPDATE_PRODUCTION_DIMENSION_FAIL,
  DELETE_PRODUCTION_DIMENSION_START,
  DELETE_PRODUCTION_DIMENSION_SUCCESS,
  DELETE_PRODUCTION_DIMENSION_FAIL,
  UPDATE_PRODUCTION_DIMENSION_PROPERTY_START,
  UPDATE_PRODUCTION_DIMENSION_PROPERTY_SUCCESS,
  UPDATE_PRODUCTION_DIMENSION_PROPERTY_FAIL,
  GENERATE_PRODUCTION_DIMENSIONS_START,
  GENERATE_PRODUCTION_DIMENSIONS_SUCCESS,
  GENERATE_PRODUCTION_DIMENSIONS_FAIL,
  FIND_PRODUCTION_DIMENSION_EXPENSES_START,
  FIND_PRODUCTION_DIMENSION_EXPENSES_SUCCESS,
  FIND_PRODUCTION_DIMENSION_EXPENSES_FAIL,
  UPLOAD_PRODUCTION_SALARIES_START,
  UPLOAD_PRODUCTION_SALARIES_SUCCESS,
  UPLOAD_PRODUCTION_SALARIES_FAIL,
  CLEAR_PRODUCTION_SALARIES_STATE,
  IMPORT_PRODUCTION_SALARIES_START,
  IMPORT_PRODUCTION_SALARIES_SUCCESS,
  IMPORT_PRODUCTION_SALARIES_FAIL,
  FIND_PRODUCTION_SALARY_IMPORT_TRANSFERS_START,
  FIND_PRODUCTION_SALARY_IMPORT_TRANSFERS_SUCCESS,
  FIND_PRODUCTION_SALARY_IMPORT_TRANSFERS_FAIL,
  DELETE_PRODUCTION_SALARY_IMPORT_TRANSFER_START,
  DELETE_PRODUCTION_SALARY_IMPORT_TRANSFER_SUCCESS,
  DELETE_PRODUCTION_SALARY_IMPORT_TRANSFER_FAIL,

  FIND_LEDGER_RECIEPTS_TRANSFERS_START,
  FIND_LEDGER_RECIEPTS_TRANSFERS_SUCCESS,
  FIND_LEDGER_RECIEPTS_TRANSFERS_FAIL,
  
  CREATE_LEDGER_RECIEPTS_TRANSFER_START,
  CREATE_LEDGER_RECIEPTS_TRANSFER_SUCCESS,
  CREATE_LEDGER_RECIEPTS_TRANSFER_FAIL,
  
  DELETE_LEDGER_RECIEPTS_TRANSFER_START,
  DELETE_LEDGER_RECIEPTS_TRANSFER_SUCCESS,
  DELETE_LEDGER_RECIEPTS_TRANSFER_FAIL,

  FIND_PENDING_LEDGER_RECEIPTS_START,
  FIND_PENDING_LEDGER_RECEIPTS_SUCCESS,
  FIND_PENDING_LEDGER_RECEIPTS_FAIL,

  TALENOM_FIND_TRANSFERS_START,
  TALENOM_FIND_TRANSFERS_SUCCESS,
  TALENOM_FIND_TRANSFERS_FAIL,
  
  TALENOM_DELETE_TRANSFER_START,
  TALENOM_DELETE_TRANSFER_SUCCESS,
  TALENOM_DELETE_TRANSFER_FAIL,
}

