import React from "react";
import { RouteComponentProps } from "react-router";
import { Alert } from "reactstrap";

interface IProps extends RouteComponentProps {}

const Error: React.FC<IProps> = props => {

  return (
    <React.Fragment>
      <Alert color="danger">
        Sorry. You don't have permission to access this website.
      </Alert>
    </React.Fragment>
  );
};


export default Error;
